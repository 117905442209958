import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useEffect, useRef } from 'react';

import { TMessageThread } from '@/types/message';

import CircularWithPath from '@/@mantis/components/@extended/progress/CircularWithPath';
import ScrollX from '@/@mantis/components/ScrollX';

import MessageThreadMessageItem from './MessageThreadMessageItem';

interface Props {
  messageThread: TMessageThread | null;
  isLoading?: boolean;
  isFailed?: boolean;
}

const MessageThreadMessageList: React.FC<Props> = ({
  messageThread,
  isLoading = false,
  isFailed = false,
}) => {
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [messageThread]);

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ flex: '1 1 100%' }}>
        <CircularWithPath />
      </Stack>
    );
  }

  if (isFailed) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ flex: '1 1 100%' }} gap={1}>
        <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
        <Typography variant="h6">Failed to load emails.</Typography>
      </Stack>
    );
  }

  if (!messageThread) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ flex: '1 1 100%' }} gap={1}>
        <Typography variant="h6">Select a thread to view messages.</Typography>
      </Stack>
    );
  }

  return (
    <ScrollX
      sx={{
        overflowX: 'auto',
        flex: '1 1 100%',
        display: 'flex',
      }}
    >
      <Stack direction="column" gap={2.5} sx={{ flex: '1 1 100%' }}>
        {messageThread.messages.map(message => (
          <MessageThreadMessageItem message={message} messageSession={messageThread.session} />
        ))}

        <Box ref={bottomRef} />
      </Stack>
    </ScrollX>
  );
};

export default MessageThreadMessageList;
