import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { matchPath } from 'react-router-dom';

import { NavItemType } from '@/@mantis/types/menu';
import { PMPRoutes } from '@/config/routes';

const menuItems: { items: NavItemType[] } = {
  items: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'group',
      url: PMPRoutes.dashboard,
      icon: DashboardOutlinedIcon,
      getIsSelected: pathname => PMPRoutes.dashboard === pathname,
    },
    {
      id: 'communications',
      title: 'Communications',
      type: 'group',
      children: [
        {
          id: 'buildings',
          title: 'Buildings',
          type: 'item',
          url: PMPRoutes.buildingList,
          icon: HomeWorkOutlinedIcon,
          getIsSelected: pathname =>
            [
              PMPRoutes.buildingList,
              PMPRoutes.buildingView,
              PMPRoutes.buildingViewDocumentList,
              PMPRoutes.buildingViewKnowledgeList,
              PMPRoutes.buildingViewUnitList,
            ].some(path => !!matchPath(pathname, path)),
        },
        {
          id: 'inbound-emails',
          title: 'Inbound Emails',
          type: 'item',
          url: PMPRoutes.inboundEmailList,
          icon: MailOutlineIcon,
          getIsSelected: pathname => PMPRoutes.inboundEmailList === pathname,
        },
        {
          id: 'inbound-sms',
          title: 'Inbound SMS',
          type: 'item',
          url: PMPRoutes.inboundSmsList,
          icon: SmsOutlinedIcon,
          getIsSelected: pathname => PMPRoutes.inboundSmsList === pathname,
        },
        {
          id: 'inbound-calls',
          title: 'Inbound Calls',
          type: 'item',
          url: PMPRoutes.inboundCallList,
          icon: PhoneCallbackIcon,
          getIsSelected: pathname => PMPRoutes.inboundCallList === pathname,
        },
      ],
    },
  ],
};

export default menuItems;
