import ButtonBase from '@mui/material/ButtonBase';
import { SxProps } from '@mui/material/styles';
import { Link, LinkProps } from 'react-router-dom';

import { APP_DEFAULT_PATH } from '@/@mantis/config';
import { useAuth } from '@/context/AuthProvider';

import LogoIcon from './LogoIcon';
import Logo from './LogoMain';

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: LinkProps['to'];
}

const LogoSection: React.FC<Props> = ({ reverse, isIcon, sx, to }) => {
  const { isAuthenticated } = useAuth();

  return (
    <ButtonBase
      disableRipple
      {...(isAuthenticated && { component: Link, to: !to ? APP_DEFAULT_PATH : to, sx })}
    >
      {isIcon ? <LogoIcon /> : <Logo reverse={reverse} />}
    </ButtonBase>
  );
};

export default LogoSection;
