import MenuFoldOutlined from '@ant-design/icons/MenuFoldOutlined';
import MenuUnfoldOutlined from '@ant-design/icons/MenuUnfoldOutlined';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactNode, useMemo } from 'react';

import IconButton from '@/@mantis/components/@extended/IconButton';
import { MenuOrientation, ThemeMode, DRAWER_WIDTH, MINI_DRAWER_WIDTH } from '@/@mantis/config';
import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import useConfig from '@/@mantis/hooks/useConfig';

import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

const Header: React.FC = () => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { mode, menuOrientation } = useConfig();

  const { isOpen, setIsOpen } = useDrawer();

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  const iconBackColor = mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

  // common header
  const mainHeader: ReactNode = (
    <Toolbar>
      {!isHorizontal ? (
        <IconButton
          aria-label="open drawer"
          onClick={() => setIsOpen(!isOpen)}
          edge="start"
          color="secondary"
          variant="light"
          sx={{
            color: 'text.primary',
            bgcolor: isOpen ? 'transparent' : iconBackColor,
            ml: { xs: 0, lg: -2 },
          }}
        >
          {!isOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </IconButton>
      ) : null}
      {headerContent}
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: '1px solid',
      borderBottomColor: 'divider',
      zIndex: 1200,
      width: isHorizontal
        ? '100%'
        : {
          xs: '100%',
          lg: isOpen ? `calc(100% - ${DRAWER_WIDTH}px)` : `calc(100% - ${MINI_DRAWER_WIDTH}px)`,
        },
    },
  };

  if (downLG)
    return (
      <AppBarStyled open={isOpen} {...appBar}>
        {mainHeader}
      </AppBarStyled>
    );

  return <AppBar {...appBar}>{mainHeader}</AppBar>;
};

export default Header;
