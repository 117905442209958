import React, { useCallback, useState } from 'react';
import { MessageText, useChannelStateContext, useMessageContext } from 'stream-chat-react';

import LLMChatBubble, { Rating } from '@/components/stream-chat/LLMChatBubble';
import UserChatBubble from '@/components/stream-chat/UserChatBubble';

const TerraiMessageUI: React.FC = () => {
  const { channel } = useChannelStateContext();
  const { message } = useMessageContext(); // Access the current message
  const [rating, setRating] = useState<Rating>(
    message?.own_reactions?.[0]?.rating as unknown as Rating
  );

  const onRatingChange = useCallback(
    async (_rating: Rating) => {
      const currentRating = rating;
      try {
        setRating(_rating);

        await channel?.sendReaction(message.id, {
          type: 'rate',
          rating: _rating,
          user_id: message.user?.id,
          message_id: message.id,
        });
      } catch (error) {
        setRating(currentRating);
      }
    },
    [channel, message.id, message.user?.id, rating]
  );

  if (message.user?.role === 'admin')
    return (
      <LLMChatBubble isRateable rating={rating} onRatingChange={onRatingChange}>
        <MessageText />
      </LLMChatBubble>
    );

  return (
    <UserChatBubble>
      <MessageText />
    </UserChatBubble>
  );
};

export default TerraiMessageUI;
