import { useTheme, styled } from '@mui/material/styles';

import { DRAWER_WIDTH } from './MessageChatDrawer';

const Main = styled('main', { shouldForwardProp: (prop: string) => prop !== 'isDrawerOpen' })(
  ({ isDrawerOpen }: { isDrawerOpen: boolean }) => {
    const theme = useTheme();

    return {
      display: 'flex',
      width: '100%',
      flex: '1 1 100%',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
      marginLeft: `-${DRAWER_WIDTH}px`,
      [theme.breakpoints.down('lg')]: {
        paddingLeft: 0,
        marginLeft: 0,
      },
      ...(isDrawerOpen && {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.shorter,
        }),
        marginLeft: 0,
      }),
    };
  }
);

export default {
  Main,
};
