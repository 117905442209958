import { IonImg } from '@ionic/react';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { BaseProps } from '@/types/props';

import { useHomeRoute } from '@/hooks/useHomeRoute';

interface Props extends BaseProps {
  width?: string;
  height?: string;
  to?: LinkProps['to'];
}

const TerraiLogo: React.FC<Props> = ({ width = '150px', height = 'auto', to, ...baseProps }) => {
  const homeRoute = useHomeRoute();

  return (
    <Link to={to ?? homeRoute} {...baseProps}>
      <div className="ion-padding-end">
        <IonImg src="/assets/images/terrai-logo.png" alt="Terr.ai" style={{ width, height }} />
      </div>
    </Link>
  );
};

export default TerraiLogo;
