import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonIcon,
  IonProgressBar,
} from '@ionic/react';
import { cloudUploadOutline } from 'ionicons/icons';
import { useRef } from 'react';

import { BaseProps } from '@/types/props';

import { ACCEPTED_FILE_TYPES } from '@/utils/document';

import S from './DocumentUploadCard.styles';

interface Props extends BaseProps {
  title: string;
  description: string;
  isFileUploading?: boolean;
  onFileSelect: (file: File) => void;
}

const DocumentUploadCard: React.FC<Props> = ({
  title,
  description,
  isFileUploading = false,
  onFileSelect,
  ...baseProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onFileSelect(file);
    }

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <IonCard button={!isFileUploading} onClick={() => inputRef.current?.click()} {...baseProps}>
      <IonCardHeader>
        <IonCardSubtitle>
          {title}
          <IonIcon icon={cloudUploadOutline} size="small" />
        </IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent>{description}</IonCardContent>

      {isFileUploading && <IonProgressBar type="indeterminate" />}

      <S.Input
        ref={inputRef}
        type="file"
        accept={ACCEPTED_FILE_TYPES.join(',')}
        onChange={onChange}
        disabled={isFileUploading}
      />
    </IonCard>
  );
};

export default DocumentUploadCard;
