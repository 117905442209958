import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { closeSnackbar, SnackbarKey } from 'notistack';

import IconButton from '@/@mantis/components/@extended/IconButton';

const NotistackCloseIcon: React.FC<SnackbarKey> = snackbarKey => (
  <IconButton color="default" size="small" onClick={() => closeSnackbar(snackbarKey)}>
    <CloseOutlinedIcon />
  </IconButton>
);

export default NotistackCloseIcon;
