import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { PublicRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';

import ContentGradient from '@/components/ContentGradient';
import Header from '@/components/Header';

const LogoutPage: React.FC = () => {
  const { user, signOut } = useAuth();
  const history = useHistory();

  useEffect(() => {
    signOut();
  }, [signOut, history]);

  if (!user) return <Redirect to={PublicRoutes.login} />;

  return (
    <IonPage>
      <IonContent className="login-content">
        <ContentGradient />

        <IonGrid>
          <IonRow>
            <IonCol>
              <Header logoOnly />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default LogoutPage;
