import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { environment } from '@env';

import { TBuilding } from '@/types/building';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { useAuth } from '@/context/AuthProvider';

import AddressAutocomplete from '../address/AddressAutocomplete';

interface Props {
  onClose: () => void;
  onBuildingCreated: (building: TBuilding) => void;
}

const BuildingCreateModal: React.FC<Props> = ({ onClose, onBuildingCreated }) => {
  const [name, setName] = useState('');
  const [addressText, setAddressText] = useState('');
  const { session } = useAuth();

  const createBuildingMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post<TBuilding>(
        `${environment.api}/building`,
        {
          name,
          address_text: addressText,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: data => {
      onBuildingCreated(data);
      enqueueSnackbar(`Building ${data.name} created successfully.`, {
        variant: 'success',
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to create building.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Create Building</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={e => setName(e.target.value)}
        />

        <AddressAutocomplete
          value={addressText}
          onValueChange={setAddressText}
          textFieldProps={{
            fullWidth: true,
            margin: 'dense',
            label: 'Address',
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <LoadingButton
          disabled={!name || !addressText || createBuildingMutation.isPending}
          variant="contained"
          color="primary"
          loading={createBuildingMutation.isPending}
          onClick={() => createBuildingMutation.mutate()}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default BuildingCreateModal;
