import { IonImg } from '@ionic/react';
import { chatbubblesOutline, documentsOutline, homeOutline, logOutOutline } from 'ionicons/icons';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { BaseProps } from '@/types/props';

import { HOPRoutes, PublicRoutes } from '@/config/routes';
import { useGetIsRouteAccessible } from '@/hooks/useGetIsRouteAccessible';
import { useHomeRoute } from '@/hooks/useHomeRoute';

import CircleIconButton from '@/components/CircleIconButton';

import S from './SideMenu.styles';

interface MenuItem {
  icon: string | React.ReactNode;
  routerLink: string;
  title: string;
}

interface MenuItemProps extends MenuItem, BaseProps {
  isActive?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  routerLink,
  title,
  isActive = false,
  ...baseProps
}) => (
  <CircleIconButton
    title={title}
    routerLink={routerLink}
    color={isActive ? 'transparent-active' : 'transparent'}
    shape="round"
    icon={icon}
    {...baseProps}
  />
);

const SideMenu: React.FC = () => {
  const location = useLocation();
  const homeRoute = useHomeRoute();
  const getIsRouteAccessible = useGetIsRouteAccessible();

  const centerMenuItems: MenuItem[] = useMemo(
    () =>
      [
        {
          icon: homeOutline,
          routerLink: HOPRoutes.dashboard,
          title: 'Dashboard',
        },
        { icon: documentsOutline, routerLink: HOPRoutes.documentList, title: 'Documents' },
        {
          icon: chatbubblesOutline,
          routerLink: PublicRoutes.chat,
          title: 'Chat',
        },
      ].filter(menuItem => getIsRouteAccessible(menuItem.routerLink)),
    [getIsRouteAccessible]
  );

  return (
    <S.Container>
      <S.ContainerTop>
        <MenuItem
          title="Home"
          routerLink={homeRoute}
          icon={
            <S.IonAvatar slot="icon-only">
              <IonImg src="./assets/images/terri-logo.svg" alt="Terri" />
            </S.IonAvatar>
          }
        />
      </S.ContainerTop>

      <S.ContainerCenter>
        {centerMenuItems.map(menuItem => (
          <MenuItem
            key={menuItem.title}
            {...menuItem}
            isActive={menuItem.routerLink === location.pathname}
          />
        ))}

        <MenuItem
          className="d-none d-flex-to-md"
          title="Logout"
          routerLink={PublicRoutes.logout}
          icon={logOutOutline}
        />
      </S.ContainerCenter>

      <S.ContainerBottom className="d-none-to-md">
        <MenuItem routerLink={PublicRoutes.logout} icon={logOutOutline} title="Logout" />
      </S.ContainerBottom>
    </S.Container>
  );
};

export default SideMenu;
