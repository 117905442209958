import { IonPage } from '@ionic/react';

import { BaseProps } from '@/types/props';

import IonicPageWrapper from '@/@ionic/IonicPageWrapper';

import ContentGradient from '@/components/ContentGradient';
import SideMenu from '@/components/menu/SideMenu';

import S from './FullHeightPage.styles';

export interface Props extends BaseProps {
  children: React.ReactNode;
  background?: 'transparent' | 'neutral-200' | 'darkgray-300-80';
  hasExtraTopPadding?: boolean;
}

const FullHeightPage: React.FC<Props> = ({
  children,
  background = 'neutral-200',
  hasExtraTopPadding = false,
  ...baseProps
}) => (
  <IonicPageWrapper>
    <IonPage {...baseProps}>
      <S.PageContainer>
        <ContentGradient />

        <SideMenu />

        <S.MainContainer $background={background} $hasExtraTopPadding={hasExtraTopPadding}>
          {children}
        </S.MainContainer>
      </S.PageContainer>
    </IonPage>
  </IonicPageWrapper>
);

export default FullHeightPage;
