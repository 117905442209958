import { useCallback } from 'react';

import {
  anonymousUserRoutes,
  associaAuthenticatedRoutes,
  associaPublicRoutes,
  authenticatedUserRoutes,
  authenticatedPropertyManagerRoutes,
  publicRoutes,
  Routes,
} from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { isAssociaSubdomain } from '@/utils/communities';

export const useGetIsRouteAccessible = () => {
  const { user, session, shouldHaveAuthToken, isPropertyManager, isPropertyManagerLoading } =
    useAuth();

  return useCallback(
    (path: Routes) => {
      const isNotLoggedIn = !session && !shouldHaveAuthToken;

      if (isAssociaSubdomain())
        return isNotLoggedIn
          ? (associaPublicRoutes as string[]).includes(path as string)
          : (associaAuthenticatedRoutes as string[]).includes(path as string);

      if ((publicRoutes as string[]).includes(path as string)) return true;

      if (user && user.is_anonymous)
        return (anonymousUserRoutes as string[]).includes(path as string);

      if (user && (authenticatedUserRoutes as string[]).includes(path as string)) return true;

      if (user && (isPropertyManager || isPropertyManagerLoading))
        return (authenticatedPropertyManagerRoutes as string[]).includes(path as string);

      return false;
    },
    [session, shouldHaveAuthToken, user, isPropertyManager, isPropertyManagerLoading]
  );
};
