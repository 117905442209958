import styled from '@emotion/styled';
import { IonCard } from '@ionic/react';

import { getStyledScrollbar } from '@/styles/utils/scrollbar';

const StyledIonCard = styled(IonCard)`
  height: 100%;

  ion-card-content {
    height: 100%;
    display: flex;

    ${getStyledScrollbar('dark')}

    .str-chat__message-list-scroll {
      padding: 0 !important;
    }

    .str-chat__li {
      margin-inline: 0 !important;
      padding-inline: 0 !important;
    }
  }
`;

export default {
  IonCard: StyledIonCard,
};
