import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dispatch, SetStateAction } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';

import Dot from '@/@mantis/components/@extended/Dot';
import IconButton from '@/@mantis/components/@extended/IconButton';
import { MenuOrientation, ThemeMode, NavActionType } from '@/@mantis/config';
import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import useConfig from '@/@mantis/hooks/useConfig';
import { LinkTarget, NavItemType } from '@/@mantis/types/menu';

interface Props {
  item: NavItemType;
  level: number;
  isParents?: boolean;
  setSelectedID?: Dispatch<SetStateAction<string | undefined>>;
}

const NavItem: React.FC<Props> = ({ item, level, isParents = false, setSelectedID }) => {
  const theme = useTheme();

  const { isOpen, setIsOpen } = useDrawer();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { mode, menuOrientation } = useConfig();
  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  const itemHandler = () => {
    if (downLG) setIsOpen(false);

    if (isParents && setSelectedID) {
      setSelectedID(undefined);
    }
  };

  const location = useLocation();
  const isSelected =
    item.getIsSelected?.(location.pathname) ??
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    !!matchPath(location.pathname, (item.link ?? item.url)!);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const Icon = item.icon!;
  const itemIcon = item.icon ? (
    <Icon
      style={{
        fontSize: isOpen ? '1rem' : '1.25rem',
        ...(menuOrientation === MenuOrientation.HORIZONTAL &&
          isParents && { fontSize: 20, stroke: '1.5' }),
      }}
    />
  ) : (
    false
  );

  const textColor = mode === ThemeMode.DARK ? 'grey.400' : 'text.primary';
  const iconSelectedColor = mode === ThemeMode.DARK && isOpen ? 'text.primary' : 'primary.main';

  if (menuOrientation === MenuOrientation.VERTICAL || downLG)
    return (
      <Box sx={{ position: 'relative' }}>
        <ListItemButton
          component={Link}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          to={item.url!}
          target={itemTarget}
          disabled={item.disabled}
          selected={isSelected}
          sx={{
            zIndex: 1201,
            pl: isOpen ? `${level * 28}px` : 1.5,
            py: !isOpen && level === 1 ? 1.25 : 1,
            ...(isOpen && {
              '&:hover': { bgcolor: mode === ThemeMode.DARK ? 'divider' : 'primary.lighter' },
              '&.Mui-selected': {
                bgcolor: mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
                borderRight: '2px solid',
                borderColor: 'primary.main',
                color: iconSelectedColor,
                '&:hover': {
                  color: iconSelectedColor,
                  bgcolor: mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
                },
              },
            }),
            ...(!isOpen && {
              '&:hover': { bgcolor: 'transparent' },
              '&.Mui-selected': { '&:hover': { bgcolor: 'transparent' }, bgcolor: 'transparent' },
            }),
          }}
          onClick={() => itemHandler()}
        >
          {itemIcon && (
            <ListItemIcon
              sx={{
                minWidth: 28,
                color: isSelected ? iconSelectedColor : textColor,
                ...(!isOpen && {
                  borderRadius: 1.5,
                  width: 36,
                  height: 36,
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    bgcolor: mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter',
                  },
                }),
                ...(!isOpen &&
                  isSelected && {
                  bgcolor: mode === ThemeMode.DARK ? 'primary.900' : 'primary.lighter',
                  '&:hover': {
                    bgcolor: mode === ThemeMode.DARK ? 'primary.darker' : 'primary.lighter',
                  },
                }),
              }}
            >
              {itemIcon}
            </ListItemIcon>
          )}
          {(isOpen || (!isOpen && level !== 1)) && (
            <ListItemText
              primary={
                <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                  {item.title}
                </Typography>
              }
            />
          )}
          {(isOpen || (!isOpen && level !== 1)) && item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
        {(isOpen || (!isOpen && level !== 1)) &&
          item?.actions &&
          item?.actions.map((action, index) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const ActionIcon = action.icon!;
            const callAction = action?.function;
            return (
              <IconButton
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                {...(action.type === NavActionType.FUNCTION && {
                  onClick: event => {
                    event.stopPropagation();
                    callAction();
                  },
                })}
                {...(action.type === NavActionType.LINK && {
                  component: Link,
                  to: action.url,
                  target: action.target ? '_blank' : '_self',
                })}
                color="secondary"
                variant="outlined"
                sx={{
                  position: 'absolute',
                  top: 12,
                  right: 20,
                  zIndex: 1202,
                  width: 20,
                  height: 20,
                  mr: -1,
                  ml: 1,
                  color: 'secondary.dark',
                  borderColor: isSelected ? 'primary.light' : 'secondary.light',
                  '&:hover': { borderColor: isSelected ? 'primary.main' : 'secondary.main' },
                }}
              >
                <ActionIcon style={{ fontSize: '0.625rem' }} />
              </IconButton>
            );
          })}
      </Box>
    );

  return (
    <ListItemButton
      component={Link}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      to={item.url!}
      target={itemTarget}
      disabled={item.disabled}
      selected={isSelected}
      onClick={() => itemHandler()}
      sx={{
        zIndex: 1201,
        ...(isParents && { p: 1, mr: 1 }),
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            ...(!isOpen && {
              borderRadius: 1.5,
              width: 28,
              height: 28,
              alignItems: 'center',
              justifyContent: 'flex-start',
              '&:hover': { bgcolor: 'transparent' },
            }),
            ...(!isOpen &&
              isSelected && { bgcolor: 'transparent', '&:hover': { bgcolor: 'transparent' } }),
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}

      {!itemIcon && (
        <ListItemIcon
          sx={{
            color: isSelected ? 'primary.main' : 'secondary.dark',
            ...(!isOpen && {
              borderRadius: 1.5,
              alignItems: 'center',
              justifyContent: 'flex-start',
              '&:hover': { bgcolor: 'transparent' },
            }),
            ...(!isOpen &&
              isSelected && { bgcolor: 'transparent', '&:hover': { bgcolor: 'transparent' } }),
          }}
        >
          <Dot size={4} color={isSelected ? 'primary' : 'secondary'} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Typography variant="h6" color={isSelected ? 'primary.main' : 'secondary.dark'}>
            {item.title}
          </Typography>
        }
      />
      {(isOpen || (!isOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
