import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { MenuOrientation } from '@/@mantis/config';
import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import useConfig from '@/@mantis/hooks/useConfig';

import Drawer from './drawer/Drawer';
import HorizontalBar from './drawer/HorizontalBar';
import Footer from './Footer';
import Header from './Header';

const DashboardLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { setIsOpen } = useDrawer();
  const downXL = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const location = useLocation();
  const prevLocationRef = useRef(location.pathname);

  const { container, miniDrawer, menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  useEffect(() => {
    if (!miniDrawer) {
      setIsOpen(!downXL);
    }
  }, [miniDrawer, downXL, setIsOpen]);

  useEffect(
    () => () => {
      prevLocationRef.current = location.pathname;
    },
    [location]
  );

  // Close drawer if user navigates to a different page
  useEffect(() => {
    if (prevLocationRef.current !== location.pathname) {
      setIsOpen(!downXL);
    }
  }, [location, downXL, setIsOpen]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header />
      {!isHorizontal ? <Drawer /> : <HorizontalBar />}

      <Box
        component="main"
        sx={{
          width: 'calc(100% - 260px)',
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          paddingTop: '61px !important',
          height: '100vdh',
          display: 'flex',
        }}
      >
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            ...(container && { px: { xs: 0, sm: 2 } }),
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 100%',
            pt: { xs: 2, sm: 3 },
          }}
        >
          <Box sx={{ flex: '1 1 100%', display: 'flex', flexDirection: 'column' }}>{children}</Box>

          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
