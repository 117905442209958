import { TDocumentStatus } from '@/types/document';

export const getDocumentStatus = (status: TDocumentStatus): string => {
  switch (status) {
  case 'NEW':
    return 'Uploaded';
  case 'PROCESSING':
    return 'Processing';
  case 'PROCESSED':
    return 'Processed';
  case 'FAILED':
    return 'Failed';
  case 'DELETING':
    return 'Deleting';
  case 'DELETED':
    return 'Deleted';
  default:
    return 'Unkown';
  }
};

export const ACCEPTED_FILE_TYPES = [
  '.pdf',
  '.doc',
  '.docx',
  '.xml',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/x-icon',
];
