import { createContext, PropsWithChildren } from 'react';

import config from '@/@mantis/config';
import { CustomizationProps } from '@/@mantis/types/config';

const initialState: CustomizationProps = config;

const ConfigContext = createContext(initialState);

const ConfigProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
);

export { ConfigProvider, ConfigContext };
