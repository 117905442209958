import { IonSpinner } from '@ionic/react';
import {
  addOutline,
  arrowBackOutline,
  arrowForwardOutline,
  downloadOutline,
  expandOutline,
  removeOutline,
} from 'ionicons/icons';
import { useCallback, useRef, useState } from 'react';
import { pdfjs, Document } from 'react-pdf';
import { OnDocumentLoadSuccess } from 'react-pdf/dist/cjs/shared/types';

import { BaseProps } from '@/types/props';

import { useResizeObserver } from '@/hooks/useResizeObserver';
import { trimZeroes } from '@/utils/input';
import { clamp } from '@/utils/number';

import CircleIconButton from '@/components/CircleIconButton';

import S from './PDFDocument.styles';
import PDFPage from './PDFPage';

interface Props extends BaseProps {
  url: string;
  fileName?: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const ZOOM_STEP = 25;
const ZOOM_MIN = 100;
const ZOOM_MAX = 600;

const PDFDocument: React.FC<Props> = ({ url, fileName = 'Untitled Document', ...baseProps }) => {
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [hasLoaded, setHasLoaded] = useState(false);
  const pageInputRef = useRef<HTMLInputElement | null>(null);
  const zoomInputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [docContainerWidth, setDocContainerWidth] = useState<number>(0);
  const [docContainerHeight, setDocContainerHeight] = useState<number>(0);

  const onResize = useCallback<ResizeObserverCallback>(entries => {
    const [entry] = entries;

    if (entry) {
      setDocContainerWidth(entry.contentRect.width);
      setDocContainerHeight(entry.contentRect.height);
    }
  }, []);

  const onLoadSuccess: OnDocumentLoadSuccess = useCallback(data => {
    setNumPages(data.numPages);
    setHasLoaded(true);
  }, []);

  useResizeObserver(containerRef.current, onResize);

  const handleSetCurrentPage = useCallback(
    (pageNumber: number) => {
      setCurrentPage(Math.floor(clamp(pageNumber, 1, numPages)));

      trimZeroes(pageInputRef);
    },
    [numPages]
  );

  const handleSetZoomLevel = useCallback((_zoomLevel: number, shouldClamp = true) => {
    if (shouldClamp) {
      setZoomLevel(Math.floor(clamp(_zoomLevel, ZOOM_MIN, ZOOM_MAX)));
    } else {
      setZoomLevel(Math.floor(_zoomLevel));
    }

    trimZeroes(zoomInputRef);
  }, []);

  return (
    <S.Container ref={containerRef} {...baseProps}>
      <S.DocumentContainer>
        <Document
          file={url}
          onLoadSuccess={onLoadSuccess}
          loading={<IonSpinner name="circular" />}
          error={<IonSpinner name="circular" />}
        >
          <PDFPage
            key={currentPage}
            pageNumber={currentPage}
            containerHeight={docContainerHeight}
            containerWidth={docContainerWidth}
            scale={zoomLevel / 100}
          />
        </Document>

        {numPages > 1 && (
          <>
            <S.PreviousPage>
              <CircleIconButton
                size="small"
                color="darkgray-300"
                disabled={currentPage === 1}
                icon={arrowBackOutline}
                onClick={() => handleSetCurrentPage(currentPage - 1)}
              />
            </S.PreviousPage>

            <S.NextPage>
              <CircleIconButton
                size="small"
                color="darkgray-300"
                disabled={currentPage === numPages}
                icon={arrowForwardOutline}
                onClick={() => handleSetCurrentPage(currentPage + 1)}
              />
            </S.NextPage>
          </>
        )}
      </S.DocumentContainer>

      {hasLoaded && (
        <S.ControlsContainer>
          <S.FileName>{fileName}</S.FileName>
          <S.CurrentPage>
            <S.Input
              ref={pageInputRef}
              type="number"
              min={1}
              max={numPages}
              value={currentPage}
              onChange={e => handleSetCurrentPage(Number(e.target.value))}
            />
            <span>/</span>
            <span>{numPages}</span>
          </S.CurrentPage>

          <S.Zoom>
            <S.ZoomOutButton
              disabled={zoomLevel <= ZOOM_MIN}
              icon={removeOutline}
              color="darkgray-300"
              onClick={() => handleSetZoomLevel(zoomLevel - ZOOM_STEP)}
            />
            <S.Input
              ref={zoomInputRef}
              type="number"
              min={ZOOM_MIN}
              max={ZOOM_MAX}
              value={zoomLevel}
              step={ZOOM_STEP}
              onChange={e => handleSetZoomLevel(Number(e.target.value), false)}
            />
            <S.ZoomInButton
              disabled={zoomLevel >= ZOOM_MAX}
              icon={addOutline}
              color="darkgray-300"
              onClick={() => handleSetZoomLevel(zoomLevel + ZOOM_STEP)}
            />
          </S.Zoom>

          <S.Buttons>
            <CircleIconButton
              disabled={zoomLevel === 100}
              icon={expandOutline}
              color="darkgray-300"
              size="small"
              onClick={() => handleSetZoomLevel(100)}
            />

            <CircleIconButton
              icon={downloadOutline}
              color="darkgray-300"
              href={url}
              download={fileName}
              target="_blank"
              size="small"
            />
          </S.Buttons>
        </S.ControlsContainer>
      )}
    </S.Container>
  );
};

export default PDFDocument;
