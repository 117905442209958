import { useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { cloneElement } from 'react';

interface ElevationScrollProps {
  children: React.ReactElement;
  window?: Window | Node;
}

const ElevationScroll: React.FC<ElevationScrollProps> = ({ children, window }) => {
  const theme = useTheme();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    target: window!,
  });

  theme.shadows[4] = theme.customShadows.z1;

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

export default ElevationScroll;
