import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle } from '@ionic/react';

import { BaseProps } from '@/types/props';

import { PublicRoutes } from '@/config/routes';

const CreateAccountPrompt: React.FC<BaseProps> = ({ ...baseProps }) => (
  <IonCard color="neutral-100" {...baseProps}>
    <IonCardHeader>
      <IonCardSubtitle>Create account</IonCardSubtitle>
    </IonCardHeader>

    <IonCardContent>
      Build your property profile and get instant, personalized home ownership answers.
    </IonCardContent>
    <IonCardContent>
      <IonButton routerLink={PublicRoutes.signup} color="darkgray-300" size="large" expand="block">
        Sign up
      </IonButton>
    </IonCardContent>
  </IonCard>
);

export default CreateAccountPrompt;
