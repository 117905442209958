import styled from '@emotion/styled';
import { IonButtons } from '@ionic/react';

const StyledIonButtons = styled(IonButtons)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.5rem;
`;

const SuggestionWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

export default {
  IonButtons: StyledIonButtons,
  SuggestionWrapper,
};
