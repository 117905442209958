import { PaletteColor } from '@mui/material';
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@mui/material/colors';

interface Color {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export const getPaletteColor = (color: Color): PaletteColor => ({
  light: color[50],
  main: color[500],
  dark: color[700],
  contrastText: color[50],
  lighter: color[100],
  darker: color[900],
});

export const paletteColors: PaletteColor[] = [
  getPaletteColor(red),
  getPaletteColor(pink),
  getPaletteColor(purple),
  getPaletteColor(deepPurple),
  getPaletteColor(indigo),
  getPaletteColor(blue),
  getPaletteColor(lightBlue),
  getPaletteColor(cyan),
  getPaletteColor(teal),
  getPaletteColor(green),
  getPaletteColor(lightGreen),
  getPaletteColor(lime),
  getPaletteColor(yellow),
  getPaletteColor(amber),
  getPaletteColor(orange),
  getPaletteColor(deepOrange),
  getPaletteColor(brown),
  getPaletteColor(grey),
  getPaletteColor(blueGrey),
];

export const getPaletterColorFromString = (str: string): PaletteColor =>
  paletteColors[
    str.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % paletteColors.length
  ];
