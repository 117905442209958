import { Autocomplete, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { environment } from '@env';

import { Feature, GeocodeAPIV6Response } from '@/types/mapbox';

interface Props {
  value: string;
  onValueChange: (value: string) => void;
  textFieldProps?: TextFieldProps;
}

const AddressAutocomplete: React.FC<Props> = ({ value, textFieldProps, onValueChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<readonly Feature[]>([]);

  const fetchSuggestionsQuery = useQuery({
    enabled: value.length > 2 && isOpen,
    queryKey: ['GET', 'suggestions', value],
    queryFn: async () => {
      const response = await axios.get<GeocodeAPIV6Response>(
        'https://api.mapbox.com/search/geocode/v6/forward?',
        {
          params: new URLSearchParams({
            q: value,
            access_token: environment.mapboxApiKey,
            autocomplete: 'true',
            country: 'US',
            format: 'geojson',
            language: 'en-US',
            limit: '5',
            types: 'address',
          }),
        }
      );

      return response.data;
    },
  });

  useEffect(() => {
    if (fetchSuggestionsQuery.isError || fetchSuggestionsQuery.isPending) {
      setOptions([]);
      return;
    }

    if (value.length <= 2) {
      setOptions([]);
      return;
    }

    if (fetchSuggestionsQuery.isSuccess) {
      setOptions(fetchSuggestionsQuery.data.features);
    }
  }, [
    fetchSuggestionsQuery.isError,
    fetchSuggestionsQuery.isPending,
    fetchSuggestionsQuery.isSuccess,
    fetchSuggestionsQuery.data?.features,
    value,
  ]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Autocomplete
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={(_, _value) => onValueChange(_value?.properties.full_address ?? '')}
      isOptionEqualToValue={(option, _value) =>
        option.properties.full_address === _value.properties.full_address
      }
      getOptionLabel={option => option.properties.full_address}
      options={options}
      loading={isOpen && fetchSuggestionsQuery.isPending}
      renderInput={params => (
        <TextField
          {...textFieldProps}
          {...params}
          value={value}
          onChange={e => onValueChange(e.target.value)}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {isOpen && fetchSuggestionsQuery.isPending && (
                    <CircularProgress color="inherit" size={20} />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
          }}
        />
      )}
    />
  );
};

export default AddressAutocomplete;
