import { IonCol, IonRow } from '@ionic/react';
import { documentsOutline } from 'ionicons/icons';

import { BaseProps } from '@/types/props';

import Subtitle from '@/pages/common/Subtitle';

import DocumentUploadCard from '@/components/document/DocumentUploadCard';
import LLMChatBubble from '@/components/stream-chat/LLMChatBubble';
import TopicsCard, { Topic } from '@/components/TopicsCard';

import S from './DocumentListEmptyState.styles';

interface Props extends BaseProps {
  onFileSelect: (file: File) => void;
  isFileUploading: boolean;
  showOnlyTitleAndUpload?: boolean;
}

const topics: Topic[] = [
  {
    title: 'Safe and Smart',
    children: (
      <S.TopicContent>
        Terr.ai is designed to provide you with peace of mind when uploading confidential files. We
        leverage powerful analytics to provide you with meaningful insights from your documents.
        Whether it's demystifying HOA bylaws, extracting critical dates from warranties, or
        simplifying insurance policies, our platform ensures your data is not only safe but also
        works smarter for you. Experience the perfect blend of security and intelligence with
        Terr.ai.
      </S.TopicContent>
    ),
  },
  {
    title: 'Trust and Transparency',
    children: (
      <S.TopicContent>
        Terr.ai is designed to provide you with peace of mind when uploading confidential files. We
        leverage powerful analytics to provide you with meaningful insights from your documents.
        Whether it's demystifying HOA bylaws, extracting critical dates from warranties, or
        simplifying insurance policies, our platform ensures your data is not only safe but also
        works smarter for you. Experience the perfect blend of security and intelligence with
        Terr.ai.
      </S.TopicContent>
    ),
  },
  {
    title: 'Any Questions?',
    children: (
      <S.TopicContent>
        Terr.ai is designed to provide you with peace of mind when uploading confidential files. We
        leverage powerful analytics to provide you with meaningful insights from your documents.
        Whether it's demystifying HOA bylaws, extracting critical dates from warranties, or
        simplifying insurance policies, our platform ensures your data is not only safe but also
        works smarter for you. Experience the perfect blend of security and intelligence with
        Terr.ai.
      </S.TopicContent>
    ),
  },
];

const DocumentListEmptyState: React.FC<Props> = ({
  onFileSelect,
  isFileUploading,
  showOnlyTitleAndUpload = false,
  ...baseProps
}) => (
  <>
    <IonRow {...baseProps}>
      <IonCol size="12">
        <Subtitle icon={documentsOutline} />
        <h1>You haven't uploaded any documents yet.</h1>
      </IonCol>
    </IonRow>

    {!showOnlyTitleAndUpload && (
      <IonRow>
        <IonCol size="12">
          <TopicsCard title="How we ensure your documents are protected." topics={topics} />
        </IonCol>
      </IonRow>
    )}

    <IonRow>
      {!showOnlyTitleAndUpload && (
        <IonCol size="12" sizeXl="12">
          <LLMChatBubble isFullWidth>You can upload your first document.</LLMChatBubble>
        </IonCol>
      )}

      <IonCol size="12" sizeXl="12">
        <DocumentUploadCard
          title={showOnlyTitleAndUpload ? 'Documents' : 'HOA Documents'}
          description={
            showOnlyTitleAndUpload
              ? 'Upload any document'
              : 'Stay updated with community rules and bylaws'
          }
          isFileUploading={isFileUploading}
          onFileSelect={onFileSelect}
        />
      </IonCol>
    </IonRow>
  </>
);

export default DocumentListEmptyState;
