import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import * as React from 'react';

import Breadcrumbs from '@/@mantis/components/@extended/Breadcrumbs';

interface Breadcrumb {
  label: string;
  to?: string;
  onClick?: () => void;
}

interface Props {
  title: string;
  breadcrumbs?: Breadcrumb[];
  actions?: React.ReactNode;
}

const DashboardHeader: React.FC<Props> = ({ title, breadcrumbs, actions }) => (
  <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
    {title === 'Unnamed' ? (
      <Skeleton
        variant="rectangular"
        width={200}
        height={70}
        animation="wave"
        sx={{
          background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
          backgroundSize: '200% 100%',
          animation: 'wave 1.5s infinite',
          '@keyframes wave': {
            '0%': {
              backgroundPosition: '200% 0',
            },
            '100%': {
              backgroundPosition: '-200% 0',
            },
          },
        }}
      />
    ) : (
      <Breadcrumbs
        heading={title}
        custom
        links={
          breadcrumbs?.map(crumb => ({
            title: crumb.label,
            to: crumb.to,
            onClick: crumb.onClick,
          })) ?? []
        }
      />
    )}

    {!!actions && (
      <Stack direction="row" spacing={1} ml="auto">
        {actions}
      </Stack>
    )}
  </Stack>
);

export default DashboardHeader;
