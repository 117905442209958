import { GridSortModel } from '@mui/x-data-grid';

export const getSortParams = (sortModel: GridSortModel) => {
  if (!sortModel.length) {
    return {};
  }

  const { field, sort } = sortModel[0];

  return {
    orderBy: {
      [field]: sort,
    },
  };
};
