import styled from '@emotion/styled';
import { IonButton } from '@ionic/react';

const StyledIonButton = styled(IonButton)`
  margin: 0;
`;

export default {
  IonButton: StyledIonButton,
};
