export enum PublicRoutes {
  home = '/',
  aboutUs = '/about-us',
  login = '/login',
  logout = '/logout',
  privacyPolicy = '/privacy-policy',
  termsOfService = '/terms-of-service',
  signup = '/signup',
  chat = '/chat',
  landingPage = '/landing',
}

export enum HOPRoutes {
  dashboard = '/dashboard',
  documentList = '/documents',
  documentView = '/documents/:id',
  propertyCreate = '/properties/add',
  propertyList = '/properties',
  propertyView = '/properties/:id',
}

export enum PMPRoutes {
  dashboard = '/property-manager',

  buildingList = '/property-manager/buildings',
  buildingView = '/property-manager/buildings/:id',
  buildingViewUnitList = '/property-manager/buildings/:id/units',
  buildingViewDocumentList = '/property-manager/buildings/:id/documents',
  buildingViewKnowledgeList = '/property-manager/buildings/:id/knowledge-base',

  documentView = '/property-manager/documents/:id',

  unitView = '/property-manager/units/:id',
  unitViewDocumentList = '/property-manager/units/:id/documents',
  unitViewKnowledgeList = '/property-manager/units/:id/knowledge-base',
  unitViewMessageList = '/property-manager/units/:id/messages',
  unitViewMessageView = '/property-manager/units/:id/messages/:messageId',
  unitViewTenantList = '/property-manager/units/:id/tenants',

  inboundEmailList = '/property-manager/inbound-emails',
  inboundSmsList = '/property-manager/inbound-sms',
  inboundCallList = '/property-manager/inbound-call',

  issueList = '/property-manager/issues',

  account = '/property-manager/account',
}

export type Routes = PublicRoutes | HOPRoutes | PMPRoutes;

// No chat
export const associaPublicRoutes: Routes[] = [
  PublicRoutes.home,
  PublicRoutes.aboutUs,
  PublicRoutes.login,
  PublicRoutes.logout,
  PublicRoutes.privacyPolicy,
  PublicRoutes.termsOfService,
  PublicRoutes.signup,
];

export const associaAuthenticatedRoutes = [
  ...associaPublicRoutes,
  HOPRoutes.documentList,
  HOPRoutes.documentView,
];

// With chat
// We need chat to be public, because
// we create the user within the flow.
export const publicRoutes = [
  PublicRoutes.home,
  PublicRoutes.aboutUs,
  PublicRoutes.login,
  PublicRoutes.logout,
  PublicRoutes.privacyPolicy,
  PublicRoutes.termsOfService,
  PublicRoutes.signup,
  PublicRoutes.chat,
  PublicRoutes.landingPage,
];

export const anonymousUserRoutes = [...publicRoutes, HOPRoutes.dashboard];

export const authenticatedUserRoutes = [
  ...publicRoutes,
  HOPRoutes.dashboard,
  HOPRoutes.documentList,
  HOPRoutes.documentView,
  HOPRoutes.propertyList,
  HOPRoutes.propertyCreate,
  HOPRoutes.propertyView,
];

export const authenticatedPropertyManagerRoutes = [
  ...authenticatedUserRoutes,
  // Property Manager routes
  PMPRoutes.dashboard,
  PMPRoutes.buildingList,
  PMPRoutes.buildingView,
  PMPRoutes.buildingViewUnitList,
  PMPRoutes.buildingViewDocumentList,
  PMPRoutes.buildingViewKnowledgeList,

  PMPRoutes.documentView,

  PMPRoutes.issueList,

  PMPRoutes.unitView,
  PMPRoutes.unitViewDocumentList,
  PMPRoutes.unitViewKnowledgeList,
  PMPRoutes.unitViewMessageList,
  PMPRoutes.unitViewMessageView,
  PMPRoutes.unitViewTenantList,

  PMPRoutes.account,

  PMPRoutes.inboundEmailList,
  PMPRoutes.inboundSmsList,
  PMPRoutes.inboundCallList,
];
