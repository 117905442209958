import CreateAccountPrompt from '@/components/chat/CreateAccountPrompt';
import LLMChatBubble from '@/components/stream-chat/LLMChatBubble';

interface Props {
  hasReachedMessageLimit: boolean;
}

const BeforeMessageInput: React.FC<Props> = ({ hasReachedMessageLimit }) => {
  if (!hasReachedMessageLimit) {
    return null;
  }

  return (
    <>
      <LLMChatBubble>You have reached limit of messages for unregistered users.</LLMChatBubble>

      <CreateAccountPrompt />
    </>
  );
};

export default BeforeMessageInput;
