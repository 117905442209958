import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { MenuOrientation } from '@/@mantis/config';
import useConfig from '@/@mantis/hooks/useConfig';
import DrawerHeader from '@/@mantis/layout/dashboard/drawer/DrawerHeader';


const HeaderContent: React.FC = () => {
  const { menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open />}
      <Box sx={{ width: '100%', ml: 1 }} />

      {/* {!downLG && <FullScreen />} */}
      {/* <Profile /> */}
    </>
  );
};

export default HeaderContent;
