import styled from '@emotion/styled';
import { IonCard } from '@ionic/react';

import { getStyledScrollbar } from '@/styles/utils/scrollbar';

const StyledIonCard = styled(IonCard)`
  height: 100%;
  justify-content: flex-start;

  ion-card-subtitle {
    justify-content: flex-start;
    gap: 1rem;
  }

  ion-card-content {
    ${getStyledScrollbar('dark')}
  }
`;

const SkeletonTextParagraph = styled.p`
  ion-skeleton-text:last-child {
    width: 40%;
  }
`;

export default {
  IonCard: StyledIonCard,
  SkeletonTextParagraph,
};
