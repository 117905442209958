import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import { environment } from '@env';

import { TTenant } from '@/types/tenant';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  tenant: TTenant;
  onClose: () => void;
  onTenantDeleted: (tenant: TTenant) => void;
}

const TenantDeleteModal: React.FC<Props> = ({ tenant, onClose, onTenantDeleted }) => {
  const { session } = useAuth();

  const deleteTenantMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.delete(`${environment.api}/tenant/${tenant.id}`, {
        headers: { Authorization: `Bearer ${session?.access_token}` },
      });

      return response.data;
    },
    onSuccess: data => {
      onTenantDeleted(data);
      enqueueSnackbar('Tenant deleted successfully.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to delete tenant.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this Tenant?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <LoadingButton
          variant="contained"
          color="error"
          loading={deleteTenantMutation.isPending}
          onClick={() => deleteTenantMutation.mutate()}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TenantDeleteModal;
