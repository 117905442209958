import { Box } from '@mui/material';

import Navigation from './navigation/Navigation';
import NavUser from './NavUser';

const DrawerContent: React.FC = () => (
  <>
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Navigation />
    </Box>
    <NavUser />
  </>
);

export default DrawerContent;
