import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Page, PageProps, useDocumentContext } from 'react-pdf';

interface Props extends PageProps {
  containerWidth: number;
  containerHeight: number;
  pageNumber: number;
}

const PDFPage: React.FC<Props> = ({ containerWidth, containerHeight, ...pageProps }) => {
  const pdfContext = useDocumentContext();

  const pageDimensiosQuery = useQuery({
    enabled: !!pdfContext?.pdf,
    queryKey: ['pdfContext.pdf.getPage', pageProps.pageNumber, containerWidth, containerHeight],
    queryFn: async () => {
      if (!pdfContext?.pdf) throw new Error('PDF not found.');

      const page = await pdfContext.pdf.getPage(pageProps.pageNumber);

      return {
        width: Math.abs(page.view[0] - page.view[2]),
        height: Math.abs(page.view[1] - page.view[3]),
      };
    },
  });

  const pageDimensionsProps = useMemo(() => {
    if (!pageDimensiosQuery.data) return {};

    const { width, height } = pageDimensiosQuery.data;

    const ratio = height / width;
    const containerRatio = containerHeight / containerWidth;

    if (containerRatio > ratio) return { width: containerWidth };

    return { height: containerHeight };
  }, [pageDimensiosQuery.data, containerHeight, containerWidth]);

  return <Page {...pageProps} {...pageDimensionsProps} />;
};

export default PDFPage;
