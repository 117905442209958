import MuiLoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { ButtonProps } from '@mui/material/Button';
import { alpha, styled, Theme, useTheme } from '@mui/material/styles';
import { forwardRef, ReactNode, Ref } from 'react';

import {
  ButtonVariantProps,
  ExtendedStyleProps,
  IconButtonShapeProps,
} from '@/@mantis/types/extended';
import getColors from '@/@mantis/utils/getColors';
import getShadow from '@/@mantis/utils/getShadow';

interface LoadingButtonStyleProps extends ExtendedStyleProps {
  variant: ButtonVariantProps;
  loadingPosition?: LoadingButtonProps['loadingPosition'];
}

const getColorStyle = ({
  variant,
  theme,
  color,
  loadingPosition,
}: LoadingButtonStyleProps & { theme: Theme }) => {
  const colors = getColors(theme, color);
  const { lighter, main, dark, contrastText } = colors;

  const buttonShadow = `${color}Button`;
  const shadows = getShadow(theme, buttonShadow);

  const loadingIndicator = {
    '& .MuiLoadingButton-loadingIndicator': {
      color: main,
    },
  };

  const loadingColor = {
    ...(loadingPosition &&
      loadingPosition !== 'center' && {
      color: main,
    }),
  };

  const commonShadow = {
    '&::after': {
      boxShadow: `0 0 6px 6px ${alpha(main, 0.9)}`,
    },
    '&:active::after': {
      boxShadow: `0 0 0 0 ${alpha(main, 0.9)}`,
    },
    '&:focus-visible': {
      outline: `2px solid ${dark}`,
      outlineOffset: 2,
    },
  };

  switch (variant) {
  case 'contained':
    return {
      background: main,
      color: contrastText,
      ...(loadingPosition &&
          loadingPosition !== 'center' && {
        color: contrastText,
      }),
      '& .MuiLoadingButton-loadingIndicator': {
        color: contrastText,
      },
      '&:hover': {
        background: dark,
        color: contrastText,
      },
      ...commonShadow,
    };
  case 'light':
    return {
      background: main,
      ...(loadingPosition &&
          loadingPosition !== 'center' && {
        color: contrastText,
      }),
      '& .MuiLoadingButton-loadingIndicator': {
        color: contrastText,
      },
      '&:hover': {
        background: dark,
        color: contrastText,
      },
      ...commonShadow,
    };
  case 'shadow':
    return {
      boxShadow: shadows,
      background: main,
      ...(loadingPosition &&
          loadingPosition !== 'center' && {
        color: contrastText,
      }),
      '& .MuiLoadingButton-loadingIndicator': {
        color: contrastText,
      },
      '&:hover': {
        boxShadow: 'none',
        background: dark,
        color: contrastText,
      },
      ...commonShadow,
    };
  case 'outlined':
    return {
      background: 'transparent',
      borderColor: main,
      ...loadingColor,
      ...loadingIndicator,
    };
  case 'dashed':
    return {
      background: lighter,
      borderColor: main,
      ...loadingColor,
      ...loadingIndicator,
      ...commonShadow,
    };
  case 'text':
  default:
    return {
      color: main,
      ...loadingIndicator,
      ...commonShadow,
    };
  }
};

interface StyleProps extends LoadingButtonStyleProps {
  shape?: IconButtonShapeProps;
  loading: LoadingButtonProps['loading'];
}

const LoadingButtonStyle = styled(MuiLoadingButton, {
  shouldForwardProp: prop => prop !== 'shape' && prop !== 'variant',
})(({ variant, shape, color, loading, loadingPosition }: StyleProps) => {
  const theme = useTheme();

  return {
    '::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      borderRadius: shape === 'rounded' ? '50%' : 4,
      opacity: 0,
      transition: 'all 0.5s',
    },

    ':active::after': {
      position: 'absolute',
      borderRadius: shape === 'rounded' ? '50%' : 4,
      left: 0,
      top: 0,
      opacity: 1,
      transition: '0s',
    },
    ...(variant === 'text' && {
      ...getColorStyle({ variant, theme, color, loadingPosition }),
      '&.MuiButton-sizeMedium': {
        height: 36,
      },
      '&.MuiButton-sizeSmall': {
        height: 30,
      },
      '&.MuiButton-sizeLarge': {
        height: 44,
      },
    }),
    ...(shape && {
      minWidth: 0,
      '&.MuiButton-sizeMedium': {
        width: 36,
        height: 36,
      },
      '&.MuiButton-sizeSmall': {
        width: 30,
        height: 30,
      },
      '&.MuiButton-sizeLarge': {
        width: 44,
        height: 44,
      },
      ...(shape === 'rounded' && {
        borderRadius: '50%',
      }),
    }),

    ...(variant === 'outlined' && {
      border: '1px solid',
    }),
    ...(variant === 'dashed' && {
      border: '1px dashed',
    }),
    ...((variant === 'contained' || variant === 'shadow') && loading && { color: '#fff' }),
    ...(variant !== 'text' && {
      ...getColorStyle({ variant, theme, color, loadingPosition }),
    }),

    '&.Mui-disabled': {
      ...(variant !== 'text' && {
        ...getColorStyle({ variant, theme, color, loadingPosition }),
      }),
    },
  };
});

interface Props extends LoadingButtonProps {
  color?: ButtonProps['color'];
  variant?: ButtonVariantProps;
  shape?: IconButtonShapeProps;
  children: ReactNode;
}

const LoadingButton: React.ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  { variant = 'text', shape, children, color = 'primary', ...others },
  ref: Ref<HTMLButtonElement>
) => (
  <LoadingButtonStyle
    ref={ref}
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variant={variant!}
    shape={shape}
    loadingPosition={others.loadingPosition}
    loading={others.loading}
    color={color}
    {...others}
  >
    {children}
  </LoadingButtonStyle>
);

LoadingButton.displayName = 'LoadingButton';

export default forwardRef(LoadingButton);
