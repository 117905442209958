import { TPropertyManager } from '@/types/propertyManager';

export const PROPERTY_MANAGER_STORAGE_KEY = 'terrai.property-manager';

export const getPropertyManagerFromLocalStorage = () =>
  localStorage.getItem(PROPERTY_MANAGER_STORAGE_KEY)
    ? (JSON.parse(localStorage.getItem(PROPERTY_MANAGER_STORAGE_KEY) as string) as TPropertyManager)
    : null;

export const setPropertyManagerInLocalStorage = (propertyManager: TPropertyManager) =>
  localStorage.setItem(PROPERTY_MANAGER_STORAGE_KEY, JSON.stringify(propertyManager));

export const clearPropertyManagerFromLocalStorage = () =>
  localStorage.removeItem(PROPERTY_MANAGER_STORAGE_KEY);
