import { BaseProps } from '@/types/props';

import S from './Tabs.styles';

interface Props extends BaseProps {
  children: React.ReactNode;
}

const Tabs: React.FC<Props> = ({ children, ...baseProps }) => (
  <S.TabsContainer {...baseProps}>{children}</S.TabsContainer>
);

export default Tabs;
