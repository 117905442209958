import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useCallback, useState } from 'react';

import { TMessageThread } from '@/types/message';

import IconButton from '@/@mantis/components/@extended/IconButton';

interface Props {
  messageThread: TMessageThread | null;
  onMessageSend: (message: string, messageThread: TMessageThread) => void;
}

const MessageChatInput: React.FC<Props> = ({ messageThread, onMessageSend }) => {
  const [message, setMessage] = useState('');

  const handleOnSend = useCallback(() => {
    if (!message.trim()) return;
    if (!messageThread) return;

    onMessageSend(message, messageThread);
    setMessage('');
  }, [message, messageThread, onMessageSend]);

  return (
    <Stack>
      <TextField
        fullWidth
        multiline
        rows={3}
        placeholder="Your Message..."
        value={message}
        onChange={e => setMessage(e.target.value)}
        onSubmit={handleOnSend}
        variant="standard"
        sx={{
          '& .MuiInput-root:before': { borderBottomColor: 'divider' },
        }}
      />
      <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1} mt={1}>
        {!!messageThread && (
          <Typography variant="caption" color="text.secondary">
            Responding to: {messageThread.session.from.source}
          </Typography>
        )}

        <IconButton disabled={!message.trim()} color="primary" onClick={handleOnSend} size="large">
          <SendOutlinedIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default MessageChatInput;
