import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Box, Card, CardContent, Stack, TextField, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';

import { TSMS } from '@/types/sms';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { formatFullDateTime } from '@/utils/date';
import { capitalizeFirstLetter } from '@/utils/text';

interface MobileSMSCardProps {
  sms: TSMS;
  onSMSAccept: (sms: TSMS) => void;
  onSMSArchive: (sms: TSMS) => void;
  isSending: boolean;
  isArchiving: boolean;
}

const MobileSMSCard: React.FC<MobileSMSCardProps> = ({
  sms,
  onSMSAccept,
  onSMSArchive,
  isSending,
  isArchiving,
}) => {
  const theme = useTheme();
  const [aiResponse, setAiResponse] = useState(sms.first_ai_message);

  const handleSend = () => {
    onSMSAccept({
      ...sms,
      first_ai_message: aiResponse,
    });
  };

  return (
    <Card sx={{ mb: 2, width: '100%' }}>
      <CardContent>
        <Stack spacing={2}>
          <Box>
            <Typography variant="caption" color="textSecondary">
              ID: {sms.external_id}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {sms.session?.tenant?.full_name
                ? capitalizeFirstLetter(sms.session.tenant.full_name)
                : 'Unknown'}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {sms.session?.from_phone_number}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" fontWeight="bold">
              Message:
            </Typography>
            <Typography variant="body2">{sms.first_user_message}</Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
              AI Response:
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={aiResponse}
              onChange={e => setAiResponse(e.target.value)}
              sx={{
                backgroundColor: theme.palette.background.paper,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.divider,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
            />
          </Box>

          <Box>
            <Typography variant="caption" color="textSecondary" display="block">
              {formatFullDateTime(new Date(sms.created_at))}
            </Typography>
          </Box>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSend}
              startIcon={<SendOutlinedIcon />}
              loading={isSending}
              fullWidth
            >
              Send
            </LoadingButton>

            <LoadingButton
              variant="contained"
              color="secondary"
              onClick={() => onSMSArchive(sms)}
              loading={isArchiving}
              fullWidth
            >
              Archive
            </LoadingButton>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default MobileSMSCard;
