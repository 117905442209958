import { IonContent, IonMenuToggle } from '@ionic/react';
import React from 'react';

import { BaseProps } from '@/types/props';

import Menu from '@/components/menu/Menu';

import S from './HamburgerMenu.styles';

const HamburgerMenu: React.FC<BaseProps> = baseProps => (
  <S.IonMenu contentId="main-content" {...baseProps}>
    <IonContent className="ion-padding">
      <IonMenuToggle autoHide>
        <Menu orientation="vertical" />
      </IonMenuToggle>
    </IonContent>
  </S.IonMenu>
);

export default HamburgerMenu;
