import { Theme } from '@mui/material/styles';

export default function DataGrid(theme: Theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          border: '1px solid',
          borderColor: theme.palette.divider,
          flex: '1 1 100px',

          '&.MuiDataGrid-root--densityComfortable': {
            fontSize: '14px',
          },

          '& .MuiDataGrid-columnHeader': {
            backgroundColor: theme.palette.grey[50],
            borderBottom: '2px solid',
            borderBottomColor: theme.palette.divider,
          },

          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 700,
          },

          '& .MuiDataGrid-columnSeparator': {
            color: theme.palette.divider,
          },

          '& .MuiDataGrid-row': {
            borderBottom: '1px solid',
            borderBottomColor: theme.palette.divider,
          },

          '& .MuiDataGrid-footerContainer': {
            backgroundColor: theme.palette.grey[50],
            flex: '0 0 auto',
          },

          '& .MuiDataGrid-cell a': {
            fontWeight: 600,
          },

          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },

          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },

          '& .MuiDataGrid-row--dynamicHeight .MuiDataGrid-cell': {
            paddingTop: '0.25rem',
            paddingBottom: '0.25rem',
            display: 'flex',
            alignItems: 'center',
          },

          '&&.MuiDataGrid-root--densityComfortable .MuiDataGrid-row--dynamicHeight .MuiDataGrid-cell':
            {
              paddingTop: '1rem',
              paddingBottom: '1rem',
            },
        },
      },
    },
  };
}
