import BorderOutlined from '@ant-design/icons/BorderOutlined';
import DownOutlined from '@ant-design/icons/DownOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState, useMemo } from 'react';
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom';

import Dot from '@/@mantis/components/@extended/Dot';
import IconButton from '@/@mantis/components/@extended/IconButton';
import Transitions from '@/@mantis/components/@extended/Transitions';
import { MenuOrientation, ThemeMode } from '@/@mantis/config';
import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import useConfig from '@/@mantis/hooks/useConfig';
import { NavItemType } from '@/@mantis/types/menu';

import NavItem from './NavItem';

type VirtualElement = {
  getBoundingClientRect: () => ClientRect | DOMRect;
  contextElement?: Element;
};

type ListItemClick =
  | React.MouseEvent<HTMLButtonElement>
  | React.MouseEvent<HTMLAnchorElement>
  | React.MouseEvent<HTMLDivElement, MouseEvent>
  | undefined;

// mini-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '& > .MuiBox-root': {
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 25,
      ...(theme.direction !== 'rtl' && { left: -5 }),
      ...(theme.direction === 'rtl' && { right: -5 }),
      width: 10,
      height: 10,
      background: theme.palette.background.paper,
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 120,
      borderLeft: '2px solid',
      borderLeftColor: theme.palette.divider,
      borderBottom: '2px solid',
      borderBottomColor: theme.palette.divider,
    },
  },
  '&[data-popper-placement="right-end"]': {
    '.MuiPaper-root': {
      marginBottom: -8,
    },
    '&:before': {
      top: 'auto',
      bottom: 5,
    },
  },
}));

interface Props {
  menu: NavItemType;
  level: number;
  parentId: string;
  setSelectedItems: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedItems: string | undefined;
  setSelectedLevel: React.Dispatch<React.SetStateAction<number>>;
  selectedLevel: number;
}

const NavCollapse: React.FC<Props> = ({
  menu,
  level,
  parentId,
  setSelectedItems,
  selectedItems,
  setSelectedLevel,
  selectedLevel,
}) => {
  const theme = useTheme();
  const { isOpen } = useDrawer();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { mode, menuOrientation } = useConfig();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | null | undefined>(null);
  const [anchorEl, setAnchorEl] = useState<
    VirtualElement | (() => VirtualElement) | null | undefined
      >(null);

  const [anchorElCollapse, setAnchorElCollapse] = React.useState<null | HTMLElement>(null);

  const openCollapse = Boolean(anchorElCollapse);
  const handleClickCollapse = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorElCollapse(event.currentTarget);
  };
  const handleCloseCollapse = () => {
    setAnchorElCollapse(null);
  };

  const handleClick = (event: ListItemClick, isRedirect: boolean) => {
    setAnchorEl(null);
    setSelectedLevel(level);
    if (isOpen) {
      setOpen(!open);
      setSelected(!selected ? menu.id : null);
      setSelectedItems(!selected ? menu.id : '');
      if (menu.url && isRedirect) history.push(menu.url);
    } else {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handlerIconLink = () => {
    if (!isOpen) {
      if (menu.url) history.push(menu.url);
      setSelected(menu.id);
    }
  };

  const handleHover = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | undefined
  ) => {
    setAnchorEl(event?.currentTarget);
  };

  const miniMenuOpened = Boolean(anchorEl);

  const handleClose = () => {
    setOpen(false);
    if (!miniMenuOpened) {
      if (!menu.url) {
        setSelected(null);
      }
    }
    setAnchorEl(null);
  };

  useMemo(() => {
    if (selected === selectedItems) {
      if (level === 1) {
        setOpen(true);
      }
    } else if (level === selectedLevel) {
      setOpen(false);
      if (!miniMenuOpened && !isOpen && !selected) {
        setSelected(null);
      }
      if (isOpen) {
        setSelected(null);
      }
    }
  }, [selectedItems, level, selected, miniMenuOpened, isOpen, selectedLevel]);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === menu.url) {
      setSelected(menu.id);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const checkOpenForParent = (child: NavItemType[], id: string) => {
    child.forEach((item: NavItemType) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false);

    if (!miniMenuOpened) {
      setSelected(null);
    } else {
      setAnchorEl(null);
    }

    if (menu.children) {
      menu.children.forEach((item: NavItemType) => {
        if (item.children?.length) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          checkOpenForParent(item.children, menu.id!);
        }

        if (item.link && !!matchPath(item.link, pathname)) {
          setSelected(menu.id);
          setOpen(true);
        }

        if (item.url === pathname) {
          setSelected(menu.id);
          setOpen(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu.children]);

  useEffect(() => {
    if (menu.url === pathname) {
      setSelected(menu.id);
      setAnchorEl(null);
      setOpen(true);
    }
  }, [pathname, menu]);

  const navCollapse = menu.children?.map(item => {
    switch (item.type) {
    case 'collapse':
      return (
        <NavCollapse
          key={item.id}
          setSelectedItems={setSelectedItems}
          setSelectedLevel={setSelectedLevel}
          selectedLevel={selectedLevel}
          selectedItems={selectedItems}
          menu={item}
          level={level + 1}
          parentId={parentId}
        />
      );
    case 'item':
      return <NavItem key={item.id} item={item} level={level + 1} />;
    default:
      return (
        <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Collapse or Item
        </Typography>
      );
    }
  });

  const isSelected = selected === menu.id;
  const borderIcon = level === 1 ? <BorderOutlined style={{ fontSize: '1rem' }} /> : false;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const Icon = menu.icon!;
  const menuIcon = menu.icon ? (
    <Icon style={{ fontSize: isOpen ? '1rem' : '1.25rem' }} />
  ) : (
    borderIcon
  );
  const textColor = mode === ThemeMode.DARK ? 'grey.400' : 'text.primary';
  const iconSelectedColor = mode === ThemeMode.DARK && isOpen ? 'text.primary' : 'primary.main';
  const popperId = miniMenuOpened ? `collapse-pop-${menu.id}` : undefined;
  const FlexBox = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  };

  if (menuOrientation === MenuOrientation.VERTICAL || downLG)
    return (
      <>
        <ListItemButton
          id={`${menu.id}-button`}
          selected={selected === menu.id}
          {...(!isOpen && {
            onMouseEnter: (e: ListItemClick) => handleClick(e, true),
            onMouseLeave: handleClose,
          })}
          onClick={(e: ListItemClick) => handleClick(e, true)}
          sx={{
            pl: isOpen ? `${level * 28}px` : 1.5,
            py: !isOpen && level === 1 ? 1.25 : 1,
            ...(isOpen && {
              '&:hover': { bgcolor: mode === ThemeMode.DARK ? 'divider' : 'primary.lighter' },
              '&.Mui-selected': {
                bgcolor: 'transparent',
                color: iconSelectedColor,
                '&:hover': {
                  color: iconSelectedColor,
                  bgcolor: mode === ThemeMode.DARK ? 'divider' : 'transparent',
                },
              },
            }),
            ...(!isOpen && {
              '&:hover': { bgcolor: 'transparent' },
              '&.Mui-selected': { '&:hover': { bgcolor: 'transparent' }, bgcolor: 'transparent' },
            }),
          }}
          {...(isOpen &&
            menu.isDropdown && {
            'aria-controls': openCollapse ? `${menu.id}-menu` : undefined,
            'aria-haspopup': true,
            'aria-expanded': openCollapse ? 'true' : undefined,
            onClick: handleClickCollapse,
          })}
        >
          {menuIcon && (
            <ListItemIcon
              onClick={handlerIconLink}
              sx={{
                minWidth: 28,
                color: selected === menu.id ? 'primary.main' : textColor,
                ...(!isOpen && {
                  borderRadius: 1.5,
                  width: 36,
                  height: 36,
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    bgcolor: mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter',
                  },
                }),
                ...(!isOpen &&
                  selected === menu.id && {
                  bgcolor: mode === ThemeMode.DARK ? 'primary.900' : 'primary.lighter',
                  '&:hover': {
                    bgcolor: mode === ThemeMode.DARK ? 'primary.darker' : 'primary.lighter',
                  },
                }),
              }}
            >
              {menuIcon}
            </ListItemIcon>
          )}
          {(isOpen || (!isOpen && level !== 1)) && (
            <ListItemText
              primary={
                <Typography variant="h6" color={selected === menu.id ? 'primary' : textColor}>
                  {menu.title}
                </Typography>
              }
              secondary={
                menu.caption && (
                  <Typography variant="caption" color="secondary">
                    {menu.caption}
                  </Typography>
                )
              }
            />
          )}

          {(isOpen || (!isOpen && level !== 1)) &&
            (menu?.url ? (
              <IconButton
                onClick={(event: ListItemClick) => {
                  event?.stopPropagation();
                  handleClick(event, false);
                }}
                color="secondary"
                variant="outlined"
                sx={{
                  width: 20,
                  height: 20,
                  mr: '-5px',
                  color: 'secondary.dark',
                  borderColor: open ? 'primary.light' : 'secondary.light',
                  '&:hover': { borderColor: open ? 'primary.main' : 'secondary.main' },
                }}
              >
                {miniMenuOpened || open ? (
                  <UpOutlined style={{ fontSize: '0.625rem', color: theme.palette.primary.main }} />
                ) : (
                  <DownOutlined style={{ fontSize: '0.625rem' }} />
                )}
              </IconButton>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {miniMenuOpened || open ? (
                  <UpOutlined
                    style={{
                      fontSize: '0.625rem',
                      marginLeft: 1,
                      color: theme.palette.primary.main,
                    }}
                  />
                ) : (
                  <DownOutlined style={{ fontSize: '0.625rem', marginLeft: 1 }} />
                )}
              </>
            ))}

          {!isOpen && (
            <PopperStyled
              open={miniMenuOpened}
              anchorEl={anchorEl}
              placement="right-start"
              style={{ zIndex: 2001 }}
              popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [-12, 1] } }] }}
            >
              {({ TransitionProps }) => (
                <Transitions in={miniMenuOpened} {...TransitionProps}>
                  <Paper
                    sx={{
                      overflow: 'hidden',
                      mt: 1.5,
                      boxShadow: theme.customShadows.z1,
                      backgroundImage: 'none',
                      border: '1px solid',
                      borderColor: 'divider',
                    }}
                  >
                    {/* <ClickAwayListener onClickAway={handleClose}> */}
                    {/* eslint-disable-next-line max-len */}
                    {/* <SimpleBar sx={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '50vh' }}> */}
                    {navCollapse}
                    {/* </SimpleBar> */}
                    {/* </ClickAwayListener> */}
                  </Paper>
                </Transitions>
              )}
            </PopperStyled>
          )}
        </ListItemButton>
        {isOpen && !menu?.isDropdown && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List sx={{ p: 0 }}>{navCollapse}</List>
          </Collapse>
        )}

        {isOpen && menu?.isDropdown && (
          <Menu
            id={`${menu.id}-menu`}
            aria-labelledby={`${menu.id}-button`}
            anchorEl={anchorElCollapse}
            open={openCollapse}
            onClose={handleCloseCollapse}
            onClick={handleCloseCollapse}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{
              '& .MuiPaper-root': { boxShadow: theme.shadows[2] },
              '& .MuiListItemButton-root': { pl: 2 },
            }}
          >
            {navCollapse}
          </Menu>
        )}
      </>
    );

  return (
    <ListItemButton
      {...(menu?.url && { component: Link, to: menu.url })}
      id={`boundary-${popperId}`}
      disableRipple
      selected={isSelected}
      onMouseEnter={handleHover}
      onMouseLeave={handleClose}
      onClick={handleHover}
      aria-describedby={popperId}
      sx={{ '&.Mui-selected': { bgcolor: 'transparent' } }}
    >
      <Box onClick={handlerIconLink} sx={FlexBox}>
        {menuIcon && (
          <ListItemIcon
            sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 28, color: 'secondary.dark' }}
          >
            {menuIcon}
          </ListItemIcon>
        )}
        {!menuIcon && level !== 1 && (
          <ListItemIcon
            sx={{
              my: 'auto',
              minWidth: !menu.icon ? 18 : 28,
              bgcolor: 'transparent',
              '&:hover': { bgcolor: 'transparent' },
            }}
          >
            <Dot size={4} color={isSelected ? 'primary' : 'secondary'} />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Typography variant="body1" color="inherit" sx={{ my: 'auto' }}>
              {menu.title}
            </Typography>
          }
        />
        {miniMenuOpened ? <RightOutlined /> : <DownOutlined />}
      </Box>

      {anchorEl && (
        <PopperStyled
          id={popperId}
          open={miniMenuOpened}
          anchorEl={anchorEl}
          placement="right-start"
          style={{ zIndex: 2001 }}
          modifiers={[{ name: 'offset', options: { offset: [-10, 0] } }]}
        >
          {({ TransitionProps }) => (
            <Transitions in={miniMenuOpened} {...TransitionProps}>
              <Paper
                sx={{
                  overflow: 'hidden',
                  mt: 1.5,
                  py: 0.5,
                  boxShadow: theme.shadows[8],
                  backgroundImage: 'none',
                }}
              >
                {/* <ClickAwayListener onClickAway={handleClose}> */}
                {/* eslint-disable-next-line max-len */}
                {/* <SimpleBar sx={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '50vh' }}> */}
                {navCollapse}
                {/* </SimpleBar> */}
                {/* </ClickAwayListener> */}
              </Paper>
            </Transitions>
          )}
        </PopperStyled>
      )}
    </ListItemButton>
  );
};

export default NavCollapse;
