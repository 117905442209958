import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { DrawerContextProps } from '@/@mantis/types/config';

const DrawerContext = createContext<DrawerContextProps | undefined>(undefined);

const DrawerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const value = useMemo(
    () => ({
      isOpen,
      setIsOpen,
    }),
    [isOpen]
  );

  return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};

export const useDrawer = () => {
  const context = useContext(DrawerContext);

  if (context === undefined) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }

  return context;
};

export default DrawerProvider;
