import styled from '@emotion/styled';

const TopicContent = styled.p`
  font-size: 0.65rem;
  margin: 0;
`;

export default {
  TopicContent,
};
