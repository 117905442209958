import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { from, to } from '@/styles/breakpoints';

import { Props } from './FullHeightPage';

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  ${to.md} {
    flex-direction: column;
  }
`;

const MainContainer = styled.div<{
  $background?: Props['background'];
  $hasExtraTopPadding?: Props['hasExtraTopPadding'];
}>`
  flex: 1 1 100%;
  height: 100%;
  padding: 3rem 1rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;

  ${({ $hasExtraTopPadding }) =>
    $hasExtraTopPadding &&
    css`
      padding-top: 7rem;
    `}

  ${from.lg} {
    border-radius: 1rem 1rem 0 0;
  }

  ${({ $background }) =>
    $background === 'transparent' &&
    css`
      --ion-card-background: var(--figma-color-ocean-8);
      --ion-card-color: var(--figma-color-ocean-400);
      background-color: transparent;

      ion-card-note,
      ion-card-subtitle {
        --color: var(--figma-color-ocean-300);
      }
    `}

  ${({ $background }) =>
    $background === 'neutral-200' &&
    css`
      --ion-card-background: var(--figma-color-neutral-200);
      --ion-card-color: var(--figma-color-darkgray-200);
      background-color: var(--figma-color-neutral-100);

      ion-card-note,
      ion-card-subtitle {
        --color: var(--figma-color-darkgray-100);
      }
    `}

  ${({ $background }) =>
    $background === 'darkgray-300-80' &&
    css`
      --ion-card-background: var(--figma-color-darkgray-300-80);
      --ion-card-color: var(--figma-color-neutral-300);
      background-color: var(--figma-color-darkgray-300-80);
      backdrop-filter: blur(10rem);

      ion-card-note,
      ion-card-subtitle {
        --color: var(--figma-color-neutral-300);
      }

      ion-card-content {
        a,
        em,
        b,
        strong,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          --color: var(--figma-color-neutral-100);
        }
      }
    `}

  ${to.md} {
    order: -1;
    padding: 1rem;

    ${({ $hasExtraTopPadding }) =>
    $hasExtraTopPadding &&
      css`
        padding-top: 2rem;
      `}
  }
`;

export default {
  PageContainer,
  MainContainer,
};
