import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonButton } from '@ionic/react';

const StyledIonButton = styled(IonButton)<{
  $isActive?: boolean;
  $isStartChat?: boolean;
}>`
  ${({ $isActive }) =>
    !!$isActive &&
    css`
      text-decoration: underline;
    `}

  --color: var(--figma-color-darkgray-300) !important;

  ${({ $isStartChat }) =>
    !!$isStartChat &&
    css`
      --background: var(--figma-color-neutral-100) !important;
      --background-activated: var(--figma-color-neutral-200) !important;
      --background-focused: var(--figma-color-neutral-200) !important;
      --background-hover: var(--figma-color-neutral-200) !important;
    `}

  &::part(native) {
    font-size: 0.75rem;

    ${({ $isStartChat }) =>
    !!$isStartChat &&
      css`
        height: 3rem;
        padding: 1rem 1.25rem;
      `}
  }
`;

export default {
  IonButton: StyledIonButton,
};
