import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button, Stack, Typography } from '@mui/material';

interface Props {
  addEntityButtonIcon?: React.ReactNode;
  addEntityButtonText?: string;
  entityName: string;
  entityNamePlural?: string;
  onAddEntityClick?: () => void;
}

const NoRowsOverlay: React.FC<Props> = ({
  addEntityButtonIcon,
  addEntityButtonText,
  entityName,
  entityNamePlural = `${entityName}s`,
  onAddEntityClick,
}) => (
  <Stack direction="column" justifyContent="center" alignItems="center" height="100%" gap={1}>
    <Typography variant="body1">No {entityNamePlural} found.</Typography>
    {onAddEntityClick && (
      <Button
        variant="outlined"
        color="primary"
        startIcon={addEntityButtonIcon ?? <AddOutlinedIcon />}
        size="small"
        onClick={onAddEntityClick}
      >
        {addEntityButtonText || `Add ${entityName}`}
      </Button>
    )}
  </Stack>
);

export default NoRowsOverlay;
