import styled from '@emotion/styled';

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export default {
  TabsContainer,
};
