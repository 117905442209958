import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react';
import Editor from 'react-simple-wysiwyg';

import { environment } from '@env';

import { TBuildingContact } from '@/types/building';

import { useAuth } from '@/context/AuthProvider';

interface BulkEmailModalProps {
  open: boolean;
  onClose: () => void;
  contacts: TBuildingContact[];
  onSend: (subject: string, body: string, recipients: string[]) => void;
  buildingId: number;
}

const BulkEmailModal: React.FC<BulkEmailModalProps> = ({
  open,
  onClose,
  contacts,
  onSend,
  buildingId,
}) => {
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [signature, setSignature] = useState('');
  const [selectedContacts, setSelectedContacts] = useState<TBuildingContact[]>(contacts);

  const { session } = useAuth();

  const { data: signatureData } = useQuery({
    queryKey: ['buildingSignature', buildingId],
    queryFn: async () => {
      const response = await axios.get<{ signature: string }>(
        `${environment.api}/building/${buildingId}/signature`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
    enabled: open,
  });

  React.useEffect(() => {
    if (signatureData?.signature) {
      setSignature(signatureData.signature);
    }
  }, [signatureData]);

  // Reset selected contacts when contacts prop changes
  React.useEffect(() => {
    setSelectedContacts(contacts);
  }, [contacts]);

  const handleSend = () => {
    const fullBody = signature ? `${body}<br><br>${signature}` : body;
    onSend(
      subject,
      fullBody,
      selectedContacts.map(contact => contact.email)
    );

    // Clear fields after sending
    setSubject('');
    setBody('');
  };

  // Filter out selected contacts from available options
  const availableContacts = contacts.filter(
    contact => !selectedContacts.some(selected => selected.id === contact.id)
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Send Bulk Email</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Subject"
          value={subject}
          onChange={e => setSubject(e.target.value)}
          margin="normal"
        />
        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
          Body
        </Typography>
        <Editor
          containerProps={{
            style: {
              minHeight: '200px',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              marginBottom: '16px',
            },
          }}
          value={body}
          onChange={e => setBody(e.target.value)}
        />
        <Editor
          containerProps={{
            style: {
              minHeight: '150px',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              marginBottom: '16px',
            },
          }}
          value={signature}
          onChange={e => setSignature(e.target.value)}
          placeholder="Email signature"
        />
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Recipients
        </Typography>
        <Autocomplete
          multiple
          value={selectedContacts}
          onChange={(_, newValue) => setSelectedContacts(newValue)}
          options={[...selectedContacts, ...availableContacts]}
          getOptionLabel={option => `${option.tenantName} (${option.email})`}
          renderInput={params => <TextField {...params} label="Select Recipients" />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions
          sx={{ mt: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSend}
          variant="contained"
          color="primary"
          disabled={selectedContacts.length === 0 || !subject || !body}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkEmailModal;
