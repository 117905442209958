import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { ColorProps } from '@/@mantis/types/extended';
import getColors from '@/@mantis/utils/getColors';

interface Props {
  color?: ColorProps;
  size?: number;
  variant?: string;
  sx?: BoxProps['sx'];
}

export const Dot: React.FC<Props> = ({ color, size, variant, sx }) => {
  const theme = useTheme();
  const colors = getColors(theme, color || 'primary');
  const { main } = colors;

  return (
    <Box
      sx={{
        width: size || 8,
        height: size || 8,
        borderRadius: '50%',
        bgcolor: variant === 'outlined' ? '' : main,
        ...(variant === 'outlined' && { border: `1px solid ${main}` }),
        ...sx,
      }}
    />
  );
};

export default Dot;
