import styled from '@emotion/styled';
import { IonRow } from '@ionic/react';

const HeaderIonRow = styled(IonRow)`
  margin-bottom: 5rem;
`;

export default {
  HeaderIonRow,
};
