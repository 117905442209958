import { css } from '@emotion/react';

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

const variablesIonic = css`
  :root {
    --ion-font-family: 'Matter', sans-serif;
    --ion-item-background: transparent;
    --ion-item-color: var(--figma-color-darkgray-300);

    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #282625;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** Custom: neutral-100 **/
    --ion-color-neutral-100: var(--figma-color-neutral-100);
    --ion-color-neutral-100-rgb: var(--figma-color-neutral-100-rgb);
    --ion-color-neutral-100-contrast: var(--figma-color-darkgray-200);
    --ion-color-neutral-100-contrast-rgb: var(--figma-color-darkgray-200-rgb);
    --ion-color-neutral-100-shade: var(--figma-color-darkgray-100);
    --ion-color-neutral-100-tint: var(--figma-color-neutral-200);

    /** Custom: neutral-200 **/
    --ion-color-neutral-200: var(--figma-color-neutral-200);
    --ion-color-neutral-200-rgb: var(--figma-color-neutral-200-rgb);
    --ion-color-neutral-200-contrast: var(--figma-color-darkgray-200);
    --ion-color-neutral-200-contrast-rgb: var(--figma-color-darkgray-200-rgb);
    --ion-color-neutral-200-shade: var(--figma-color-darkgray-100);
    --ion-color-neutral-200-tint: var(--figma-color-neutral-200);

    /** Custom: neutral-300 **/
    --ion-color-neutral-300: var(--figma-color-neutral-300);
    --ion-color-neutral-300-rgb: var(--figma-color-neutral-300-rgb);
    --ion-color-neutral-300-contrast: var(--figma-color-darkgray-200);
    --ion-color-neutral-300-contrast-rgb: var(--figma-color-darkgray-200-rgb);
    --ion-color-neutral-300-shade: var(--figma-color-darkgray-100);
    --ion-color-neutral-300-tint: var(--figma-color-neutral-200);

    /** Custom: darkgray-300 **/
    --ion-color-darkgray-300: var(--figma-color-darkgray-300);
    --ion-color-darkgray-300-rgb: var(--figma-color-darkgray-300-rgb);
    --ion-color-darkgray-300-contrast: var(--figma-color-neutral-100);
    --ion-color-darkgray-300-contrast-rgb: var(--figma-color-neutral-100-rgb);
    --ion-color-darkgray-300-shade: var(--figma-color-darkgray-200);
    --ion-color-darkgray-300-tint: var(--figma-color-black);

    /** Custom: darkgray-300-80 **/
    --ion-color-darkgray-300-80: var(--figma-color-darkgray-300-80);
    --ion-color-darkgray-300-80-rgb: var(--figma-color-darkgray-300-80-rgb);
    --ion-color-darkgray-300-80-contrast: var(--figma-color-neutral-100);
    --ion-color-darkgray-300-80-contrast-rgb: var(--figma-color-neutral-100-rgb);
    --ion-color-darkgray-300-80-shade: var(--figma-color-darkgray-200);
    --ion-color-darkgray-300-80-tint: var(--figma-color-black);

    /** Custom: ocean-8 **/
    --ion-color-ocean-8: var(--figma-color-ocean-8);
    --ion-color-ocean-8-rgb: var(--figma-color-ocean-8-rgb);
    --ion-color-ocean-8-contrast: var(--figma-color-ocean-400);
    --ion-color-ocean-8-contrast-rgb: var(--figma-color-ocean-400-rgb);
    --ion-color-ocean-8-shade: var(--figma-color-ocean-16);
    --ion-color-ocean-8-tint: var(--figma-color-ocean-4);
  }

  .ion-color-neutral-100 {
    --ion-color-base: var(--ion-color-neutral-100);
    --ion-color-base-rgb: var(--ion-color-neutral-100-rgb);
    --ion-color-contrast: var(--ion-color-neutral-100-contrast);
    --ion-color-contrast-rgb: var(--ion-color-neutral-100-contrast-rgb);
    --ion-color-shade: var(--ion-color-neutral-100-shade);
    --ion-color-tint: var(--ion-color-neutral-100-tint);
  }

  .ion-color-neutral-200 {
    --ion-color-base: var(--ion-color-neutral-200);
    --ion-color-base-rgb: var(--ion-color-neutral-200-rgb);
    --ion-color-contrast: var(--ion-color-neutral-200-contrast);
    --ion-color-contrast-rgb: var(--ion-color-neutral-200-contrast-rgb);
    --ion-color-shade: var(--ion-color-neutral-200-shade);
    --ion-color-tint: var(--ion-color-neutral-200-tint);
  }

  .ion-color-neutral-300 {
    --ion-color-base: var(--ion-color-neutral-300);
    --ion-color-base-rgb: var(--ion-color-neutral-300-rgb);
    --ion-color-contrast: var(--ion-color-neutral-300-contrast);
    --ion-color-contrast-rgb: var(--ion-color-neutral-300-contrast-rgb);
    --ion-color-shade: var(--ion-color-neutral-300-shade);
    --ion-color-tint: var(--ion-color-neutral-300-tint);
  }

  .ion-color-darkgray-300 {
    --ion-color-base: var(--ion-color-darkgray-300);
    --ion-color-base-rgb: var(--ion-color-darkgray-300-rgb);
    --ion-color-contrast: var(--ion-color-darkgray-300-contrast);
    --ion-color-contrast-rgb: var(--ion-color-darkgray-300-contrast-rgb);
    --ion-color-shade: var(--ion-color-darkgray-300-shade);
    --ion-color-tint: var(--ion-color-darkgray-300-tint);
  }

  .ion-color-darkgray-300-80 {
    --ion-color-base: var(--ion-color-darkgray-300-80);
    --ion-color-base-rgb: var(--ion-color-darkgray-300-80-rgb);
    --ion-color-contrast: var(--ion-color-darkgray-300-80-contrast);
    --ion-color-contrast-rgb: var(--ion-color-darkgray-300-80-contrast-rgb);
    --ion-color-shade: var(--ion-color-darkgray-300-80-shade);
    --ion-color-tint: var(--ion-color-darkgray-300-80-tint);
  }

  .ion-color-ocean-8 {
    --ion-color-base: var(--ion-color-ocean-8);
    --ion-color-base-rgb: var(--ion-color-ocean-8-rgb);
    --ion-color-contrast: var(--ion-color-ocean-8-contrast);
    --ion-color-contrast-rgb: var(--ion-color-ocean-8-contrast-rgb);
    --ion-color-shade: var(--ion-color-ocean-8-shade);
    --ion-color-tint: var(--ion-color-ocean-8-tint);
  }

  .ion-color-transparent {
    --ion-color-base: transparent;
    --ion-color-base-rgb: #ffffff00;
    --ion-color-contrast: var(--figma-color-darkgray-300);
    --ion-color-contrast-rgb: var(--figma-color-darkgray-300-rgb);
    --ion-color-shade: var(--figma-color-neutral-200);
    --ion-color-tint: var(--figma-color-white);
  }

  .ion-color-transparent-active {
    --ion-color-base: var(--figma-color-neutral-100);
    --ion-color-base-rgb: var(--figma-color-neutral-100-rgb);
    --ion-color-contrast: var(--figma-color-darkgray-300);
    --ion-color-contrast-rgb: var(--figma-color-darkgray-300-rgb);
    --ion-color-shade: var(--figma-color-neutral-200);
    --ion-color-tint: var(--figma-color-white);
  }
`;

export default variablesIonic;
