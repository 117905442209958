import DownOutlined from '@ant-design/icons/DownOutlined';
import GroupOutlined from '@ant-design/icons/GroupOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import { ClickAwayListener } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fragment, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import Transitions from '@/@mantis/components/@extended/Transitions';
import { MenuOrientation } from '@/@mantis/config';
import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import useConfig from '@/@mantis/hooks/useConfig';
import { NavItemType } from '@/@mantis/types/menu';

import NavCollapse from './NavCollapse';
import NavItem from './NavItem';

interface Props {
  item: NavItemType;
  lastItem: number;
  remItems: NavItemType[];
  lastItemId: string;
  setSelectedID: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedID: string | undefined;
  setSelectedItems: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedItems: string | undefined;
  setSelectedLevel: React.Dispatch<React.SetStateAction<number>>;
  selectedLevel: number;
}

type VirtualElement = {
  getBoundingClientRect: () => ClientRect | DOMRect;
  contextElement?: Element;
};

const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 5,
    left: 32,
    width: 12,
    height: 12,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderWidth: '6px',
    borderStyle: 'solid',
    // eslint-disable-next-line max-len
    borderColor: `${theme.palette.background.paper} transparent transparent ${theme.palette.background.paper}`,
  },
}));

const NavGroup: React.FC<Props> = ({
  item,
  lastItem,
  remItems,
  lastItemId,
  selectedID,
  setSelectedID,
  setSelectedItems,
  selectedItems,
  setSelectedLevel,
  selectedLevel,
}) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { isOpen } = useDrawer();

  const { menuOrientation } = useConfig();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const [anchorEl, setAnchorEl] = useState<
    VirtualElement | (() => VirtualElement) | null | undefined
      >(null);
  const [currentItem, setCurrentItem] = useState(item);

  const openMini = Boolean(anchorEl);

  useEffect(() => {
    if (lastItem) {
      if (item.id === lastItemId) {
        const localItem = { ...item };
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const elements = remItems.map((ele: NavItemType) => ele.elements!);
        localItem.children = elements.flat(1);

        setCurrentItem(localItem);
      } else {
        setCurrentItem(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, lastItem, downLG]);

  const checkOpenForParent = (child: NavItemType[], id: string) => {
    child.forEach((ele: NavItemType) => {
      if (ele.children?.length) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        checkOpenForParent(ele.children, currentItem.id!);
      }

      if (ele.url && !!matchPath(ele?.link ?? ele.url, pathname)) {
        setSelectedID(id);
      }
    });
  };

  const checkSelectedOnload = (data: NavItemType) => {
    const childrens = data.children ? data.children : [];
    childrens.forEach((itemCheck: NavItemType) => {
      if (itemCheck?.children?.length) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        checkOpenForParent(itemCheck.children, currentItem.id!);
      }

      if (itemCheck.url && !!matchPath(itemCheck.link ?? itemCheck.url, pathname)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setSelectedID(currentItem.id!);
      }
    });
  };

  useEffect(() => {
    checkSelectedOnload(currentItem);
    if (openMini) setAnchorEl(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, currentItem]);

  const handleClick = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | undefined
  ) => {
    if (!openMini) {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSelected = selectedID === currentItem.id;

  // eslint-disable-next-line
  const Icon = currentItem?.icon!;
  const itemIcon = currentItem?.icon ? (
    <Icon
      style={{
        fontSize: 20,
        stroke: '1.5',
        color: isSelected ? theme.palette.primary.main : theme.palette.secondary.dark,
      }}
    />
  ) : null;

  const navCollapse = item.children?.map((menuItem, index) => {
    switch (menuItem.type) {
    case 'collapse':
      return (
        <NavCollapse
          key={menuItem.id}
          menu={menuItem}
          setSelectedItems={setSelectedItems}
          setSelectedLevel={setSelectedLevel}
          selectedLevel={selectedLevel}
          selectedItems={selectedItems}
          level={1}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          parentId={currentItem.id!}
        />
      );
    case 'item':
      return <NavItem key={menuItem.id} item={menuItem} level={1} />;
    default:
      return (
      // eslint-disable-next-line react/no-array-index-key
        <Typography key={index} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
        </Typography>
      );
    }
  });

  const moreItems = remItems.map((itemRem: NavItemType, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={i}>
      {itemRem.url ? (
        <NavItem item={itemRem} level={1} />
      ) : (
        itemRem.title && (
          <Typography variant="caption" sx={{ pl: 2 }}>
            {itemRem.title} {itemRem.url}
          </Typography>
        )
      )}

      {itemRem?.elements?.map(menu => {
        switch (menu.type) {
        case 'collapse':
          return (
            <NavCollapse
              key={menu.id}
              menu={menu}
              level={1}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              parentId={currentItem.id!}
              setSelectedItems={setSelectedItems}
              setSelectedLevel={setSelectedLevel}
              selectedLevel={selectedLevel}
              selectedItems={selectedItems}
            />
          );
        case 'item':
          return <NavItem key={menu.id} item={menu} level={1} />;
        default:
          return (
            <Typography key={menu.id} variant="h6" color="error" align="center">
                Menu Items Error
            </Typography>
          );
        }
      })}
    </Fragment>
  ));

  // menu list collapse & items
  const items = currentItem.children?.map(menu => {
    switch (menu?.type) {
    case 'collapse':
      return (
        <NavCollapse
          key={menu.id}
          menu={menu}
          level={1}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          parentId={currentItem.id!}
          setSelectedItems={setSelectedItems}
          setSelectedLevel={setSelectedLevel}
          selectedLevel={selectedLevel}
          selectedItems={selectedItems}
        />
      );
    case 'item':
      return <NavItem key={menu.id} item={menu} level={1} />;
    default:
      return (
        <Typography key={menu?.id} variant="h6" color="error" align="center">
            Menu Items Error
        </Typography>
      );
    }
  });

  const popperId = openMini ? `group-pop-${item.id}` : undefined;

  if (menuOrientation === MenuOrientation.VERTICAL || downLG)
    return (
      <List
        subheader={
          item.title ? (
            isOpen && (
              <Box sx={{ pl: 3, mb: 1.5 }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {item.title}
                </Typography>
                {item.caption && (
                  <Typography variant="caption" color="secondary">
                    {item.caption}
                  </Typography>
                )}
              </Box>
            )
          ) : (
            <Divider sx={{ my: 0.5 }} />
          )
        }
        sx={{ mt: isOpen && item.title ? 1.5 : 0, py: 0, zIndex: 0 }}
      >
        {navCollapse}
      </List>
    );

  return (
    <List>
      <ListItemButton
        selected={isSelected}
        sx={{
          p: 1,
          my: 0.5,
          mr: 1,
          display: 'flex',
          alignItems: 'center',
          '&.Mui-selected': { bgcolor: 'transparent' },
        }}
        onMouseEnter={handleClick}
        onClick={handleClick}
        onMouseLeave={handleClose}
        aria-describedby={popperId}
      >
        {itemIcon && (
          <ListItemIcon sx={{ minWidth: 28 }}>
            {currentItem.id === lastItemId ? (
              <GroupOutlined style={{ fontSize: 20, stroke: '1.5' }} />
            ) : (
              itemIcon
            )}
          </ListItemIcon>
        )}
        <ListItemText
          sx={{ mr: 1 }}
          primary={
            <Typography variant="body1" color={isSelected ? 'primary.main' : 'secondary.dark'}>
              currentItem.title
            </Typography>
          }
        />
        {openMini ? (
          <DownOutlined style={{ fontSize: 16, stroke: '1.5' }} />
        ) : (
          <RightOutlined style={{ fontSize: 16, stroke: '1.5' }} />
        )}
        {anchorEl && (
          <PopperStyled
            id={popperId}
            open={openMini}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{ zIndex: 2001 }}
          >
            {({ TransitionProps }) => (
              <Transitions in={openMini} {...TransitionProps}>
                <Paper
                  sx={{ mt: 0.5, py: 1.25, boxShadow: theme.shadows[8], backgroundImage: 'none' }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box
                      sx={{
                        minWidth: 200,
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 170px)',
                      }}
                    >
                      {currentItem.id !== lastItemId ? items : moreItems}
                    </Box>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </PopperStyled>
        )}
      </ListItemButton>
    </List>
  );
};

export default NavGroup;
