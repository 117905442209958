import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Chip, ChipOwnProps } from '@mui/material';
import { useMemo } from 'react';

import { TDocumentStatus } from '@/types/document';

interface Props {
  status?: TDocumentStatus;
}

const DocumentStatusChip: React.FC<Props> = ({ status }) => {
  const color: ChipOwnProps['color'] = useMemo(() => {
    switch (status) {
    case 'NEW':
    case 'PROCESSING':
      return 'secondary';
    case 'PROCESSED':
      return 'success';
    case 'FAILED':
      return 'error';
    default:
      return 'secondary';
    }
  }, [status]);

  const icon = useMemo(() => {
    switch (status) {
    case 'NEW':
    case 'PROCESSING':
      return <AvTimerOutlinedIcon />;
    case 'PROCESSED':
      return <CheckCircleOutlinedIcon />;
    case 'FAILED':
      return <ErrorOutlineOutlinedIcon />;
    default:
      return undefined;
    }
  }, [status]);

  return <Chip icon={icon} label={status} color={color} variant="light" size="small" />;
};

export default DocumentStatusChip;
