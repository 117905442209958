import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

import { environment } from '@env';

import { TTenantContact } from '@/types/tenant';

import { useAuth } from '@/context/AuthProvider';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (message: string, recipients: string[]) => void;
  isLoading?: boolean;
  buildingId: string;
}

const BulkSMSModal: React.FC<Props> = ({ open, onClose, onSubmit, isLoading, buildingId }) => {
  const [message, setMessage] = useState('');
  const [selectedContacts, setSelectedContacts] = useState<TTenantContact[]>([]);

  const { session } = useAuth();

  const { data: contacts = [] } = useQuery({
    queryKey: ['tenantContacts', buildingId],
    queryFn: async () => {
      const response = await axios.get<TTenantContact[]>(
        `${environment.api}/tenant/contacts/building/${buildingId}`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      // Filter out contacts without phone numbers before returning
      return response.data.filter(contact => contact.phone);
    },
    enabled: open && !!session?.access_token,
  });

  // Reset selected contacts and select all valid contacts when contacts data changes
  useEffect(() => {
    if (contacts.length > 0) {
      setSelectedContacts(contacts);
    }
  }, [contacts]);

  const handleSend = () => {
    onSubmit(
      message,
      selectedContacts.map(contact => contact.tenant_id.toString())
    );
    setMessage('');
  };

  // Filter out selected contacts from available options
  const availableContacts = contacts.filter(
    contact => !selectedContacts.some(selected => selected.id === contact.id)
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Send Bulk SMS</DialogTitle>
      <DialogContent>
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Recipients ({selectedContacts.length} selected)
        </Typography>
        <Autocomplete
          multiple
          value={selectedContacts}
          onChange={(_, newValue) => setSelectedContacts(newValue)}
          options={[...selectedContacts, ...availableContacts]}
          getOptionLabel={option =>
            `${option.tenant.full_name} - Unit ${option.tenant.unit_name} (${option.phone})`
          }
          renderInput={params => <TextField {...params} label="Select Recipients" />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions
          sx={{ mb: 2 }}
        />
        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
          Message
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Enter your message here..."
          helperText={`Message will be sent to ${selectedContacts.length} recipients`}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSend}
          variant="contained"
          disabled={isLoading || !message.trim() || selectedContacts.length === 0}
        >
          Send SMS
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkSMSModal;
