export const breakpointXlUp = 1200;
export const breakpointLgDown = breakpointXlUp - 1;
export const breakpointLgUp = 992;
export const breakpointMdDown = breakpointLgUp - 1;
export const breakpointMdUp = 768;
export const breakpointSmDown = breakpointMdUp - 1;
export const breakpointSmUp = 576;
export const breakpointXsDown = breakpointSmUp - 1;
export const breakpointXsUp = 0;

export const maxWidthQuery = (width: number) => `@media screen and (max-width: ${width}px)`;

export const minWidthQuery = (width: number) => `@media screen and (min-width: ${width}px)`;

export const to = {
  xs: maxWidthQuery(breakpointXsDown),
  sm: maxWidthQuery(breakpointSmDown),
  md: maxWidthQuery(breakpointMdDown),
  lg: maxWidthQuery(breakpointLgDown),
};

export const from = {
  xs: minWidthQuery(breakpointXsUp),
  sm: minWidthQuery(breakpointSmUp),
  md: minWidthQuery(breakpointMdUp),
  lg: minWidthQuery(breakpointLgUp),
  xl: minWidthQuery(breakpointXlUp),
};
