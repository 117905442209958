import MenuFoldOutlined from '@ant-design/icons/MenuFoldOutlined';
import MenuUnfoldOutlined from '@ant-design/icons/MenuUnfoldOutlined';
import { Box, List, ListItem, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { TMessageThread } from '@/types/message';

import IconButton from '@/@mantis/components/@extended/IconButton';
import MainCard from '@/@mantis/components/MainCard';
import { ThemeMode } from '@/@mantis/config';

import S from './MessageChatContent.styles';
import MessageChatInput from './MessageChatInput';
import MessageSessionListItemContent from './MessageSessionListItemContent';
import MessageThreadMessageList from './MessageThreadMessageList';

interface Props {
  messageThread: TMessageThread | null;
  isDrawerOpen: boolean;
  isLoading?: boolean;
  isFailed?: boolean;
  onMessageSend: (message: string, messageThread: TMessageThread) => void;
  toggleIsDrawerOpen: () => void;
}

const MessageChatContent: React.FC<Props> = ({
  messageThread,
  isDrawerOpen,
  isLoading = false,
  isFailed = false,
  onMessageSend,
  toggleIsDrawerOpen,
}) => {
  const theme = useTheme();

  return (
    <S.Main isDrawerOpen={isDrawerOpen}>
      <MainCard
        content={false}
        sx={{
          bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'grey.50',
          borderRadius: '0 4px 4px 0',
          flex: '1 1 100%',
          display: 'flex',
        }}
      >
        <Stack direction="column" sx={{ flex: '1 1 100%' }}>
          <Box
            sx={{
              bgcolor: 'background.paper',
              p: 2,
              borderBottom: '1px solid',
              borderBottomColor: 'divider',
              flex: '0 0 auto',
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton onClick={toggleIsDrawerOpen} color="secondary" size="large">
                {isDrawerOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
              </IconButton>

              <List disablePadding>
                <ListItem disablePadding disableGutters>
                  <MessageSessionListItemContent messageSession={messageThread?.session ?? null} />
                </ListItem>
              </List>
            </Stack>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flex: '0 1 100%',
              p: 2,
              overflow: 'hidden',
              maxHeight: 'calc(100dvh - 580px)',
            }}
          >
            <MessageThreadMessageList
              messageThread={messageThread}
              isLoading={isLoading}
              isFailed={isFailed}
            />
          </Box>

          <Box
            sx={{
              p: 2,
              bgcolor: 'background.paper',
              borderTop: '1px solid',
              borderTopColor: 'divider',
              flex: '0 0 auto',
            }}
          >
            <MessageChatInput messageThread={messageThread} onMessageSend={onMessageSend} />
          </Box>
        </Stack>
      </MainCard>
    </S.Main>
  );
};

export default MessageChatContent;
