import '@fontsource/public-sans/400.css';
import '@fontsource/public-sans/500.css';
import '@fontsource/public-sans/600.css';
import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

import StyledSnackbarProvider from '@/@mantis/components/third-party/Notistack';
import DrawerProvider from '@/@mantis/contexts/DrawerContext';
import ThemeCustomization from '@/@mantis/theme';

import NotistackCloseIcon from './NotistackCloseIcon';

const MuiPageWrapper: React.FC<PropsWithChildren> = ({ children }) => (
  <ThemeCustomization>
    <DrawerProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100dvh',
        }}
      >
        {children}
      </Box>
      <StyledSnackbarProvider
        maxSnack={5}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        action={NotistackCloseIcon}
      />
    </DrawerProvider>
  </ThemeCustomization>
);

export default MuiPageWrapper;
