import styled from '@emotion/styled';

const ImageSizingWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 66.6667%;
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default {
  ImageSizingWrapper,
  ImageContainer,
};
