import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Box, Card, CardContent, Stack, TextField, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';

import { TEmail } from '@/types/email';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { formatFullDateTime } from '@/utils/date';
import { capitalizeFirstLetter } from '@/utils/text';

interface MobileEmailCardProps {
  email: TEmail;
  onEmailAccept: (email: TEmail) => void;
  onEmailArchive: (email: TEmail) => void;
  isSending: boolean;
  isArchiving: boolean;
}

const MobileEmailCard: React.FC<MobileEmailCardProps> = ({
  email,
  onEmailAccept,
  onEmailArchive,
  isSending,
  isArchiving,
}) => {
  const theme = useTheme();
  const [aiResponse, setAiResponse] = useState(email.first_ai_message);

  const handleSend = () => {
    onEmailAccept({
      ...email,
      first_ai_message: aiResponse,
    });
  };

  return (
    <Card sx={{ mb: 2, width: '100%' }}>
      <CardContent>
        <Stack spacing={2}>
          <Box>
            <Typography variant="caption" color="textSecondary">
              ID: {email.thread_id}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {email.session?.from_name
                ? capitalizeFirstLetter(email.session.from_name)
                : 'Unknown'}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {email.session?.from_email}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" fontWeight="bold">
              Subject:
            </Typography>
            <Typography variant="body1">{email.thread_title}</Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" fontWeight="bold">
              Message:
            </Typography>
            <Typography variant="body2">{email.first_user_message}</Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
              AI Response:
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={aiResponse}
              onChange={e => setAiResponse(e.target.value)}
              sx={{
                backgroundColor: theme.palette.background.paper,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.divider,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
            />
          </Box>

          <Box>
            <Typography variant="caption" color="textSecondary" display="block">
              {formatFullDateTime(new Date(email.thread_created_at))}
            </Typography>
          </Box>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSend}
              startIcon={<SendOutlinedIcon />}
              loading={isSending}
              fullWidth
            >
              Send
            </LoadingButton>

            <LoadingButton
              variant="contained"
              color="secondary"
              onClick={() => onEmailArchive(email)}
              loading={isArchiving}
              fullWidth
            >
              Archive
            </LoadingButton>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default MobileEmailCard;
