import { IonSpinner } from '@ionic/react';
import { useEffect, useRef } from 'react';
import {
  DefaultStreamChatGenerics,
  TypingIndicatorProps,
  useChannelStateContext,
  useChatContext,
  useTypingContext,
} from 'stream-chat-react';

import { BaseProps } from '@/types/props';

import LLMChatBubble from './LLMChatBubble';

interface Props extends BaseProps, TypingIndicatorProps {}

const TypingIndicator: React.FC<Props> = ({ threadList, className, style }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { channelConfig, thread } =
    useChannelStateContext<DefaultStreamChatGenerics>('TypingIndicator');
  const { client } = useChatContext<DefaultStreamChatGenerics>('TypingIndicator');
  const { typing = {} } = useTypingContext<DefaultStreamChatGenerics>('TypingIndicator');

  const typingInChannel = !threadList
    ? Object.values(typing).filter(
      ({ parent_id, user }) => user?.id !== client.user?.id && !parent_id
    )
    : [];

  const typingInThread = threadList
    ? Object.values(typing).filter(
      ({ parent_id, user }) => user?.id !== client.user?.id && parent_id === thread?.id
    )
    : [];

  const isTypingActive =
    (threadList && typingInThread.length > 0) || (!threadList && typingInChannel.length > 0);

  useEffect(() => {
    if (channelConfig?.typing_events === false) return;

    if (!isTypingActive) return;

    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [isTypingActive, channelConfig?.typing_events]);

  if (channelConfig?.typing_events === false) {
    return null;
  }

  if (!isTypingActive) return null;

  return (
    <LLMChatBubble className={className} style={style} ref={ref}>
      <IonSpinner name="dots" />
    </LLMChatBubble>
  );
};

export default TypingIndicator;
