import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLayoutEffect, useState } from 'react';

import { HORIZONTAL_MAX_ITEM, MenuOrientation } from '@/@mantis/config';
import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import useConfig from '@/@mantis/hooks/useConfig';
import menuItem from '@/@mantis/menu-items';
import { NavItemType } from '@/@mantis/types/menu';

import NavGroup from './NavGroup';
import NavItem from './NavItem';

const Navigation: React.FC = () => {
  const { menuOrientation } = useConfig();
  const { isOpen } = useDrawer();
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const [selectedID, setSelectedID] = useState<string | undefined>('');
  const [selectedItems, setSelectedItems] = useState<string | undefined>('');
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [menuItems, setMenuItems] = useState<{ items: NavItemType[] }>({ items: [] });

  useLayoutEffect(() => {
    setMenuItems(menuItem);
    // eslint-disable-next-line
  }, [menuItem]);

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems.items.length - 1;
  let remItems: NavItemType[] = [];
  let lastItemId: string;

  // first it checks menu item is more than giving HORIZONTAL_MAX_ITEM
  // after that get lastItemid by giving horizontal max item and it sets
  // horizontal menu by giving horizontal max item lastly slice menuItem
  // from array and set into remItems

  if (lastItem && lastItem < menuItems.items.length) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    lastItemId = menuItems.items[lastItem - 1].id!;
    lastItemIndex = lastItem - 1;
    remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map(item => ({
      title: item.title,
      elements: item.children,
      icon: item.icon,
      ...(item.url && {
        url: item.url,
      }),
    }));
  }

  const navGroups = menuItems.items.slice(0, lastItemIndex + 1).map((item, index) => {
    switch (item.type) {
    case 'group':
      if (item.url && item.id !== lastItemId) {
        return (
          <List key={item.id} {...(isHorizontal && { sx: { mt: 0.5 } })}>
            {!isHorizontal && index !== 0 && <Divider sx={{ my: 0.5 }} />}
            <NavItem item={item} level={1} isParents setSelectedID={setSelectedID} />
          </List>
        );
      }

      return (
        <NavGroup
          key={item.id}
          setSelectedID={setSelectedID}
          setSelectedItems={setSelectedItems}
          setSelectedLevel={setSelectedLevel}
          selectedLevel={selectedLevel}
          selectedID={selectedID}
          selectedItems={selectedItems}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          lastItem={lastItem!}
          remItems={remItems}
          lastItemId={lastItemId}
          item={item}
        />
      );
    default:
      return (
        <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
        </Typography>
      );
    }
  });

  return (
    <Box
      sx={{
        pt: !isOpen || isHorizontal ? 0 : 2,
        ...(!isHorizontal && { '& > ul:first-of-type': { mt: 0 } }),
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block',
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;
