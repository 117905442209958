import styled from '@emotion/styled';
import { IonCard } from '@ionic/react';

const StyledIonCard = styled(IonCard)`
  width: 100%;
  ion-card-subtitle ion-icon {
    font-size: 1.5rem;
  }
`;

export default {
  IonCard: StyledIonCard,
};
