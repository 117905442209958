import {
  OAuthResponse,
  Session,
  SignInWithOAuthCredentials,
  SignInWithPasswordCredentials,
  SignUpWithPasswordCredentials,
  User,
} from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { environment } from '@env';

import { TPropertyManager } from '@/types/propertyManager';

import {
  clearPropertyManagerFromLocalStorage,
  getPropertyManagerFromLocalStorage,
  setPropertyManagerInLocalStorage,
} from '@/localStorage/propertyManager';
import { getSupabaseSessionFromLocalStorage } from '@/localStorage/supabase';
import { supabase } from '@/utils/supabaseClient';

type AuthResponse = {
  data: {
    user: User | null;
    session: Session | null;
  };
  error: Error | null;
};

type AuthContextType = {
  signInWithPassword: (credentials: SignInWithPasswordCredentials) => Promise<AuthResponse>;
  signInWithOAuth: (credentials: SignInWithOAuthCredentials) => Promise<OAuthResponse>;
  signInAnonymously: () => Promise<AuthResponse>;
  signUp: (credentials: SignUpWithPasswordCredentials) => Promise<AuthResponse>;
  signOut: () => Promise<void>;
  user: User | null;
  session: Session | null;
  shouldHaveAuthToken: boolean;
  isAuthenticated: boolean;
  isPropertyManager: boolean;
  isPropertyManagerLoading: boolean;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const [session, setSession] = useState<Session | null>(getSupabaseSessionFromLocalStorage());

  // Haing Auth Token means that user is most likely logged in to an existing account,
  // but we might not yet have fetched their user details, so we use hasAuthToken to keep
  // track for edge cases.
  const [hasAuthToken, setHasAuthToken] = useState<boolean>(!!session);

  // If we're being redirected from OAuth, we get access_token in the location hash,
  // but there's a tick delay before it gets processed and stored as Auth Token in Local Storage.
  // At the same time, on the next tick, the access_token hash will disappear.
  // But we normally set hasAuthToken after auth state changes, but setState has a tick delay,
  // so there'll be a single tick when the Auth Token is present, but it's not yet reflected in
  // React state.
  // To handle that we set the flag already to true.
  useEffect(() => {
    if (location.hash.includes('access_token')) setHasAuthToken(true);
  }, [location.hash]);

  const shouldHaveAuthToken = useMemo(
    () => hasAuthToken || location.hash.includes('access_token'),
    [hasAuthToken, location]
  );

  const user = useMemo(() => session?.user ?? null, [session]);

  const isAuthenticated = useMemo(() => !!user, [user]);

  const fetchPropertyManagerQuery = useQuery({
    queryKey: ['GET', 'property-manager', 'me', session?.access_token],
    gcTime: 0,
    queryFn: async () => {
      try {
        if (!session?.access_token) return null;

        const storedPropertyManager = getPropertyManagerFromLocalStorage();

        if (storedPropertyManager && storedPropertyManager.user_id === user?.id) return true;

        if (storedPropertyManager) clearPropertyManagerFromLocalStorage();

        const response = await axios.get<TPropertyManager>(
          `${environment.api}/property-manager/me`,
          {
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
            },
          }
        );

        setPropertyManagerInLocalStorage(response.data);

        return response.data;
      } catch {
        clearPropertyManagerFromLocalStorage();

        return null;
      }
    },
  });

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, _session) => {
      setSession(_session);
      setHasAuthToken(!!_session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const signInWithPassword = useCallback(
    async (credentials: SignInWithPasswordCredentials): Promise<AuthResponse> => {
      if (user?.is_anonymous) {
        await supabase.auth.updateUser(credentials);
      }

      return supabase.auth.signInWithPassword(credentials);
    },
    [user]
  );

  const signInWithOAuth = useCallback(
    async (credentials: SignInWithOAuthCredentials) => {
      if (user?.is_anonymous) return supabase.auth.linkIdentity(credentials);

      return supabase.auth.signInWithOAuth(credentials);
    },
    [user]
  );

  const signInAnonymously = useCallback(async () => supabase.auth.signInAnonymously(), []);

  const signUp = useCallback(
    async (credentials: SignUpWithPasswordCredentials): Promise<AuthResponse> => {
      if (user?.is_anonymous) {
        await supabase.auth.updateUser(credentials);
        return supabase.auth.signInWithPassword(credentials);
      }

      return supabase.auth.signUp(credentials);
    },
    [user]
  );

  const signOut = useCallback(async () => {
    setSession(null);
    clearPropertyManagerFromLocalStorage();
    await supabase.auth.signOut();
  }, []);

  const value = useMemo(
    () => ({
      signInWithOAuth,
      signInWithPassword,
      signInAnonymously,
      signUp,
      signOut,
      shouldHaveAuthToken,
      user,
      session,
      isAuthenticated,
      isPropertyManager: !!fetchPropertyManagerQuery.data,
      isPropertyManagerLoading:
        fetchPropertyManagerQuery.isLoading || location.hash.includes('access_token'),
    }),
    [
      signInWithOAuth,
      signInWithPassword,
      signInAnonymously,
      signUp,
      signOut,
      shouldHaveAuthToken,
      user,
      session,
      isAuthenticated,
      fetchPropertyManagerQuery.data,
      fetchPropertyManagerQuery.isLoading,
      location.hash,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export default AuthProvider;
