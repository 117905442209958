import styled from '@emotion/styled';
import { IonButton } from '@ionic/react';

import { to } from '@/styles/breakpoints';

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledIonButton = styled(IonButton)`
  display: block;
  width: fit-content;

  & + & {
    margin-top: 0.5rem;
  }

  ${to.sm} {
    width: 100%;
  }
`;

export default {
  Wrapper,
  IonButton: StyledIonButton,
};
