import { Fragment } from 'react';

export const listToLines = (list: string[]) =>
  list.map((item, index) =>
    index === list.length - 1 ? (
      <Fragment key={item}>{item}</Fragment>
    ) : (
      <Fragment key={item}>
        {item}
        <br />
      </Fragment>
    )
  );

export const getDummyArray = (length: number) => Array.from(Array(length));
