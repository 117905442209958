import { IonIcon } from '@ionic/react';

import { formatWeekdayDate } from '@/utils/date';

import S from './Subtitle.styles';

interface Props {
  children?: string;
  icon?: string;
}

const Subtitle: React.FC<Props> = ({ children = formatWeekdayDate(new Date()), icon }) => (
  <S.Wrapper>
    {!!icon && <IonIcon icon={icon} size="large" />}
    <S.Subtitle>{children}</S.Subtitle>
  </S.Wrapper>
);

export default Subtitle;
