import styled from '@emotion/styled';

import { to } from '@/styles/breakpoints';

import StreamChat from '@/components/stream-chat/StreamChat';

const StyledStreamChat = styled(StreamChat)`
  margin: -3rem -1rem -2.5rem -1rem;
  flex: 1 1 100%;

  ${to.md} {
    margin: -1rem -1rem -0.5rem -1rem;
  }

  .str-chat__ul,
  .str-chat__message-input,
  .str-chat__loading-channel {
    max-width: 64rem;
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
`;

export default {
  StreamChat: StyledStreamChat,
};
