// eslint-disable-next-line import/order
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import IconButton from '@/@mantis/components/@extended/IconButton';

const ContainerBox = styled(Box)`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DocumentBox = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1 1 100%;
  overflow: auto;

  .react-pdf__Document {
    margin: auto auto;
  }

  .react-pdf__Page[style] {
    background: transparent !important;
    display: flex;
  }

  .react-pdf__Page__canvas {
    margin: auto auto;
  }

  .react-pdf__Page__textContent {
    margin: auto auto;
  }

  .react-pdf__Page__annotations {
    margin: auto auto;
  }
`;

const PreviousPageIconButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
  z-index: 2;
`;

const NextPageIconButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  z-index: 2;
`;

export default {
  ContainerBox,
  DocumentBox,
  PreviousPageIconButton,
  NextPageIconButton,
};
