import { IonCardContent, IonCardHeader, IonCardSubtitle, IonSkeletonText } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Markdown from 'react-markdown';

import { environment } from '@env';

import { TDocument } from '@/types/document';
import { BaseProps } from '@/types/props';

import { useAuth } from '@/context/AuthProvider';
import { getDummyArray } from '@/utils/list';

import S from './DocumentSummary.styles';

interface Props extends BaseProps {
  CollapseIcon: React.ReactNode;
  document?: TDocument;
}

const DocumentSummary: React.FC<Props> = ({ CollapseIcon, document, ...baseProps }) => {
  const { session } = useAuth();

  const getDocumentSummaryQuery = useQuery({
    enabled: !!session?.access_token && !!document?.id,
    queryKey: ['GET', 'document', document?.id, 'summary', session?.access_token],
    queryFn: async () => {
      const response = await axios.get(`${environment.api}/document/${document?.id}/summary`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      return response.data.data.summary;
    },
  });

  return (
    <S.IonCard {...baseProps}>
      <IonCardHeader>
        <IonCardSubtitle>
          {CollapseIcon}
          Summary
        </IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent>
        {getDocumentSummaryQuery.isPending && (
          <>
            <S.SkeletonTextParagraph>
              {getDummyArray(10).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <IonSkeletonText key={i} animated />
              ))}
            </S.SkeletonTextParagraph>

            <S.SkeletonTextParagraph>
              {getDummyArray(8).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <IonSkeletonText key={i} animated />
              ))}
            </S.SkeletonTextParagraph>

            <S.SkeletonTextParagraph>
              {getDummyArray(5).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <IonSkeletonText key={i} animated />
              ))}
            </S.SkeletonTextParagraph>
          </>
        )}

        {getDocumentSummaryQuery.isSuccess && <Markdown>{getDocumentSummaryQuery.data}</Markdown>}

        {getDocumentSummaryQuery.isError && <div>Document summary not available.</div>}
      </IonCardContent>
    </S.IonCard>
  );
};

export default DocumentSummary;
