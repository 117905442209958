import { Address } from '@/types/address';

import { capitalizeFirstLetter } from '@/utils/text';

export const addressToText = (address: Address): string =>
  [
    capitalizeFirstLetter(address.street),
    capitalizeFirstLetter(address.city),
    address.state,
    capitalizeFirstLetter(address.zipcode),
  ]
    .filter(v => !!v)
    .join(', ');

export const addressTextToTwoLinesChunks = (addressText: string): string[] => {
  const chunks = addressText.split(', ');

  return [chunks[0], chunks.slice(1).join(', ')];
};
