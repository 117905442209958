import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

interface Props {
  open: boolean;
}

const DrawerHeaderStyled = styled(Box, { shouldForwardProp: prop => prop !== 'open' })(
  ({ open }: Props) => {
    const theme = useTheme();

    return {
      ...theme.mixins.toolbar,
      display: 'flex',
      alignItems: 'center',
      justifyContent: open ? 'flex-start' : 'center',
      paddingLeft: theme.spacing(open ? 3 : 0),
    };
  }
);

export default DrawerHeaderStyled;
