import { css } from '@emotion/react';

import ionicCss from '@/styles/ionic';
import streamChatCss from '@/styles/streamChat';
import typographyCss from '@/styles/typography';
import classNamesCss from '@/styles/utils/classNames';
import variablesFigma from '@/styles/variablesFigma';
import variablesIonic from '@/styles/variablesIonic';

export const globalStyles = css`
  ${variablesFigma}
  ${variablesIonic}

  body {
    font-family: 'Matter', sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: var(--figma-color-darkgray-300);
    background-color: var(--figma-color-neutral-200);
  }

  a {
    color: var(--figma-color-darkgray-300);

    &:hover {
      color: var(--figma-color-darkgray-200);
    }
  }

  #root {
    height: 100%;
  }

  ${typographyCss}
  ${ionicCss}
  ${streamChatCss}
  ${classNamesCss}
`;
