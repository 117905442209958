export const numberToSqFt = (value?: number | null) =>
  value && !Number.isNaN(value) ? `${value.toLocaleString()} sq ft` : null;

export const numberToUSD = (value?: number | null) =>
  value && !Number.isNaN(value) ? `$${value.toLocaleString()}` : null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toNumberOrNull = (value: any): number | null => {
  if (value === null || value === undefined) return null;

  const tryNumber = Number(value);

  if (Number.isNaN(tryNumber)) return null;

  return tryNumber;
};

export const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);
