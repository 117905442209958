export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Check if the cleaned number has 10 digits
  if (cleanedPhoneNumber.length !== 10) {
    // If 11 digits, take last 10
    if (cleanedPhoneNumber.length === 11) {
      return formatPhoneNumber(cleanedPhoneNumber.slice(-10));
    }
    return cleanedPhoneNumber;
  }

  // Format the number as (111) 222-3333
  return `(${cleanedPhoneNumber.slice(0, 3)}) ${cleanedPhoneNumber.slice(
    3,
    6
  )}-${cleanedPhoneNumber.slice(6)}`;
};
