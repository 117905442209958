import styled from '@emotion/styled';

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 300ms ease-in;
  overflow: hidden;
`;

export default {
  MapContainer,
};
