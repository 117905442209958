import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ButtonProps } from '@mui/material';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { ACCEPTED_FILE_TYPES } from '@/utils/document';

import S from './DocumentUploadButton.styles';

export interface API {
  click: () => void;
}

interface Props extends ButtonProps {
  isFileUploading: boolean;
  onFileSelect: (file: File) => void;
}

const DocumentUploadButton = forwardRef<API, Props>(
  ({ isFileUploading, onFileSelect, ...buttonProps }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        onFileSelect(file);
      }

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };

    useImperativeHandle(ref, () => ({
      click: () => inputRef.current?.click(),
    }));

    return (
      <>
        <LoadingButton
          {...buttonProps}
          color="primary"
          loadingPosition="start"
          loading={isFileUploading}
          size="medium"
          variant="contained"
          startIcon={<CloudUploadOutlinedIcon />}
          onClick={() => inputRef.current?.click()}
        >
          Upload Document
        </LoadingButton>

        <S.Input
          ref={inputRef}
          type="file"
          accept={ACCEPTED_FILE_TYPES.join(',')}
          onChange={onChange}
          disabled={isFileUploading}
        />
      </>
    );
  }
);

export default DocumentUploadButton;
