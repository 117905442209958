import styled from '@emotion/styled';
import { IonHeader, IonToolbar } from '@ionic/react';

const StyledIonHeader = styled(IonHeader)`
  --background: transparent;
  padding-top: 1rem;
`;

const StyledIonToolbar = styled(IonToolbar)`
  max-width: 1512px;
  text-align: center;
  margin: 0 auto;
  --background: transparent;
  --border-color: transparent;
`;

export default {
  IonHeader: StyledIonHeader,
  IonToolbar: StyledIonToolbar,
};
