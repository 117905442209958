import { Global } from '@emotion/react';
import { IonApp, IonPage } from '@ionic/react';
import { PropsWithChildren } from 'react';

import { globalStyles } from '@/styles';

import HamburgerMenu from '@/components/menu/HamburgerMenu';

// eslint-disable-next-line import/order
import '@ionic/react/css/core.css';
// eslint-disable-next-line import/order
import '@ionic/react/css/normalize.css';
// eslint-disable-next-line import/order
import '@ionic/react/css/structure.css';
// eslint-disable-next-line import/order
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
// eslint-disable-next-line import/order
import '@ionic/react/css/display.css';
// eslint-disable-next-line import/order
import '@ionic/react/css/flex-utils.css';
// eslint-disable-next-line import/order
import '@ionic/react/css/float-elements.css';
// eslint-disable-next-line import/order
import '@ionic/react/css/padding.css';
// eslint-disable-next-line import/order
import '@ionic/react/css/text-alignment.css';
// eslint-disable-next-line import/order
import '@ionic/react/css/text-transformation.css';

const IonicPageWrapper: React.FC<PropsWithChildren> = ({ children }) => (
  <IonApp>
    <Global styles={globalStyles} />
    <HamburgerMenu />
    <IonPage id="main-content">{children}</IonPage>
  </IonApp>
);

export default IonicPageWrapper;
