import styled from '@emotion/styled';
import { IonButton } from '@ionic/react';

const StyledIonButton = styled(IonButton)`
  height: 3rem;
  width: 3rem;
  flex-basis: 3rem;
  margin: 0;

  &[size='small'] {
    height: 2.5rem;
    width: 2.5rem;
    flex-basis: 2.5rem;
  }
`;

export default {
  IonButton: StyledIonButton,
};
