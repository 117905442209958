import { css } from '@emotion/react';

type Theme = 'light' | 'dark';

export const getStyledScrollbar = (theme: Theme) => css`
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  --scrollbar-color-default: ${theme === 'light'
    ? 'var(--figma-color-darkgray-100)'
    : 'var(--figma-color-neutral-300)'};
  --scrollbar-background-default: ${theme === 'light'
    ? 'var(--figma-color-neutral-200)'
    : 'var(--figma-color-darkgray-300)'};

  ::-webkit-scrollbar {
    inline-size: 6px;
    block-size: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0.25rem;
  }

  &:hover {
    scrollbar-color: var(--scrollbar-color, var(--scrollbar-color-default)) transparent;

    &::-webkit-scrollbar-thumb {
      background: var(--scrollbar-background, var(--scrollbar-background-default));
    }
  }
`;
