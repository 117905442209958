import { HOPRoutes, PublicRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { isAssociaSubdomain } from '@/utils/communities';

export const useHomeRoute = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return PublicRoutes.home;
  }

  if (isAssociaSubdomain()) {
    return HOPRoutes.documentList;
  }

  return HOPRoutes.dashboard;
};
