import {
  ChevronRight,
  Search,
  Home,
  Apartment,
  MessageSharp,
  Mail,
  Article,
  Smartphone,
} from '@mui/icons-material';
import { Button, Card, CardContent, Typography, Box, Container, Grid } from '@mui/material';
import React from 'react';

import Header from '@/components/Header';

const LandingPage = () => {
  const features = {
    homeowners: [
      {
        icon: <Search sx={{ fontSize: 32, mb: 2, color: 'primary.main' }} />,
        title: 'AI-Powered Search',
        description:
          'Get instant answers to any property-related questions using our advanced AI search',
      },
      {
        icon: <Article sx={{ fontSize: 32, mb: 2, color: 'primary.main' }} />,
        title: 'Document Analysis',
        description:
          'Upload and extract valuable insights from HOA documents, contracts, and regulations',
      },
      {
        icon: <MessageSharp sx={{ fontSize: 32, mb: 2, color: 'primary.main' }} />,
        title: 'Interactive Q&A',
        description:
          'Ask questions about your uploaded documents and get clear, accurate answers instantly',
      },
    ],
    managers: [
      {
        icon: <Article sx={{ fontSize: 32, mb: 2, color: 'success.main' }} />,
        title: 'Knowledge Base Creation',
        description:
          'Power the platform with your property data and documentation for automated responses',
      },
      {
        icon: <Mail sx={{ fontSize: 32, mb: 2, color: 'success.main' }} />,
        title: 'Email Automation',
        description:
          'Auto-respond to tenant inquiries with accurate information from your knowledge base',
      },
      {
        icon: <Smartphone sx={{ fontSize: 32, mb: 2, color: 'success.main' }} />,
        title: 'SMS Integration',
        description: 'Provide instant SMS responses to common tenant questions 24/7',
      },
    ],
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      <Header />
      {/* Hero Section */}
      <Box sx={{ bgcolor: 'background.paper', boxShadow: 1 }}>
        <Container sx={{ py: 10 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h2" gutterBottom>
                Find & Understand Property Information with AI
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                Terr.ai transforms complex property documents into clear, actionable insights.
                Upload your documents and get instant answers to any property-related questions.
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button variant="contained" color="primary">
                  Start Your Journey
                </Button>
                <Button variant="outlined" color="primary">
                  See How It Works
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ aspectRatio: '16/9', bgcolor: 'grey.200', borderRadius: 1, boxShadow: 3 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 800 600"
                  style={{ width: '100%', height: '100%' }}
                >
                  {/* Background Elements */}
                  <rect width="800" height="600" fill="#f8fafc" />
                  <path d="M0 500 Q 400 450 800 550" fill="#e2e8f0" opacity="0.5" />

                  {/* House Icon */}
                  <g transform="translate(150, 200)">
                    <path d="M100 50 L200 50 L200 180 L100 180 Z" fill="#475569" />
                    <path d="M80 80 L150 20 L220 80 L80 80" fill="#475569" />
                    <rect x="130" y="100" width="40" height="80" fill="#f8fafc" />
                  </g>

                  {/* Document Stack */}
                  <g transform="translate(400, 150)">
                    {/* Bottom Document */}
                    <rect x="0" y="20" width="160" height="200" rx="8" fill="#cbd5e1" />
                    <line x1="20" y1="60" x2="140" y2="60" stroke="#94a3b8" strokeWidth="4" />
                    <line x1="20" y1="90" x2="140" y2="90" stroke="#94a3b8" strokeWidth="4" />
                    <line x1="20" y1="120" x2="100" y2="120" stroke="#94a3b8" strokeWidth="4" />

                    {/* Middle Document */}
                    <rect x="10" y="10" width="160" height="200" rx="8" fill="#e2e8f0" />
                    <line x1="30" y1="50" x2="150" y2="50" stroke="#94a3b8" strokeWidth="4" />
                    <line x1="30" y1="80" x2="150" y2="80" stroke="#94a3b8" strokeWidth="4" />
                    <line x1="30" y1="110" x2="110" y2="110" stroke="#94a3b8" strokeWidth="4" />

                    {/* Top Document */}
                    <rect x="20" y="0" width="160" height="200" rx="8" fill="#f8fafc" />
                    <line x1="40" y1="40" x2="160" y2="40" stroke="#94a3b8" strokeWidth="4" />
                    <line x1="40" y1="70" x2="160" y2="70" stroke="#94a3b8" strokeWidth="4" />
                    <line x1="40" y1="100" x2="120" y2="100" stroke="#94a3b8" strokeWidth="4" />
                  </g>

                  {/* AI Circuit Pattern */}
                  <g transform="translate(500, 250)" stroke="#0ea5e9" strokeWidth="2" fill="none">
                    <circle cx="50" cy="50" r="20" />
                    <line x1="70" y1="50" x2="120" y2="50" />
                    <circle cx="140" cy="50" r="20" />
                    <line x1="50" y1="70" x2="50" y2="120" />
                    <circle cx="50" cy="140" r="20" />
                    <line x1="140" y1="70" x2="140" y2="120" />
                    <circle cx="140" cy="140" r="20" />
                    <path d="M70 140 Q 95 140 110 120" />
                  </g>

                  {/* Magnifying Glass */}
                  <g transform="translate(300, 350)">
                    <circle cx="40" cy="40" r="30" fill="none" stroke="#0ea5e9" strokeWidth="8" />
                    <line
                      x1="65"
                      y1="65"
                      x2="90"
                      y2="90"
                      stroke="#0ea5e9"
                      strokeWidth="8"
                      strokeLinecap="round"
                    />
                  </g>

                  {/* Decorative Dots */}
                  <g fill="#0ea5e9">
                    <circle cx="100" cy="100" r="4" />
                    <circle cx="120" cy="100" r="4" />
                    <circle cx="140" cy="100" r="4" />
                    <circle cx="700" cy="200" r="4" />
                    <circle cx="720" cy="200" r="4" />
                    <circle cx="740" cy="200" r="4" />
                  </g>
                </svg>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Mission Statement */}
      <Box sx={{ bgcolor: 'primary.main', color: 'primary.contrastText', py: 8 }}>
        <Container>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h3" gutterBottom>
              Our Mission
            </Typography>
            <Typography variant="h6" sx={{ maxWidth: '800px', mx: 'auto' }}>
              We democratize real estate knowledge through AI technology, bridging the gap between
              complex legal documents and everyday homeowners.
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* User Types Section */}
      <Container sx={{ py: 10 }}>
        <Typography variant="h3" align="center" sx={{ mb: 8 }}>
          Choose Your Path to Clarity
        </Typography>

        <Grid container spacing={3}>
          {/* Homeowners Card */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                height: '100%',
                transition: 'transform 0.3s',
                '&:hover': { transform: 'scale(1.05)' },
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <Home sx={{ fontSize: 48, color: 'primary.main', mb: 3 }} />
                <Typography variant="h4" gutterBottom>
                  For Homeowners
                </Typography>
                <Typography color="text.secondary" sx={{ mb: 3 }}>
                  Get instant answers to all your property-related questions with our AI-powered
                  platform.
                </Typography>

                <Box sx={{ mb: 4 }}>
                  {features.homeowners.map(feature => (
                    <Box key={feature.title} sx={{ display: 'flex', mb: 3 }}>
                      {feature.icon}
                      <Box sx={{ ml: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          {feature.title}
                        </Typography>
                        <Typography color="text.secondary">{feature.description}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>

                <Button variant="contained" color="primary" fullWidth endIcon={<ChevronRight />}>
                  Join as Homeowner
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {/* Property Managers Card */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                height: '100%',
                transition: 'transform 0.3s',
                '&:hover': { transform: 'scale(1.05)' },
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <Apartment sx={{ fontSize: 48, color: 'success.main', mb: 3 }} />
                <Typography variant="h4" gutterBottom>
                  For Property Managers
                </Typography>
                <Typography color="text.secondary" sx={{ mb: 3 }}>
                  Automate resident communications and save time with AI-powered responses.
                </Typography>

                <Box sx={{ mb: 4 }}>
                  {features.managers.map(feature => (
                    <Box key={feature.title} sx={{ display: 'flex', mb: 3 }}>
                      {feature.icon}
                      <Box sx={{ ml: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          {feature.title}
                        </Typography>
                        <Typography color="text.secondary">{feature.description}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>

                <Button variant="contained" color="success" fullWidth endIcon={<ChevronRight />}>
                  Join as Property Manager
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* CTA Section */}
      <Box sx={{ bgcolor: 'primary.main', color: 'primary.contrastText', py: 8 }}>
        <Container>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h3" gutterBottom>
              Got Questions? We've Got Answers!
            </Typography>
            <Typography variant="h6" sx={{ mb: 4 }}>
              Join us in making real estate less about confusion and more about confidence.
            </Typography>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'background.paper',
                color: 'primary.main',
                '&:hover': { bgcolor: 'grey.100' },
              }}
            >
              Start a Chat
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;
