import { useEffect } from 'react';

export const useResizeObserver = (
  element: Element | null,
  observerCallback: ResizeObserverCallback,
  options?: ResizeObserverOptions
) => {
  useEffect(() => {
    if (!element) return undefined;

    const observer = new ResizeObserver(observerCallback);

    observer.observe(element, options);

    return () => observer.disconnect();
  }, [element, options, observerCallback]);
};
