import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { PublicRoutes } from '@/config/routes';

const Footer: React.FC = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ p: '24px 16px 0px', mt: 'auto' }}
  >
    <Typography variant="caption">&copy; {new Date().getFullYear()} All rights reserved</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
      <Link href={PublicRoutes.aboutUs} target="_blank" variant="caption" color="text.primary">
        About Us
      </Link>
      <Link
        href={PublicRoutes.privacyPolicy}
        target="_blank"
        variant="caption"
        color="text.primary"
      >
        Privacy Policy
      </Link>
      <Link
        href={PublicRoutes.termsOfService}
        target="_blank"
        variant="caption"
        color="text.primary"
      >
        Terms of Service
      </Link>
    </Stack>
  </Stack>
);

export default Footer;
