import styled from '@emotion/styled';
import { IonButton } from '@ionic/react';

const StyledIonButton = styled(IonButton)`
  opacity: 1 !important;
  margin-bottom: 0.5rem;
`;

export default {
  IonButton: StyledIonButton,
};
