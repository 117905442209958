import { ExtractParams } from '@/types/router';

import { Routes } from '@/config/routes';

export const getResolvedRoutePath = <T extends Routes>(route: T, params: ExtractParams<T>) => {
  let resolvedRoute: string = route;

  Object.entries(params).forEach(([key, value]) => {
    resolvedRoute = resolvedRoute.replace(`/:${key}`, `/${value}`);
  });

  return resolvedRoute;
};
